import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  PartialWithFieldValue,
} from 'firebase/firestore'
import {
  addDoc,
  and,
  collection,
  collectionGroup,
  deleteField,
  doc,
  documentId,
  getCountFromServer,
  getDoc,
  query,
  serverTimestamp,
  updateDoc,
  where,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  fieldsWithExtensionsFromTeachingPlanUploadType,
  schema,
  writeSchema,
} from './schema'
import type { FirestoreTeachingPlan } from './schema'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { TeachingPlan } from '../../models/TeachingPlan'
import {
  convertDocumentSnapshotToModel,
  modelItemStream,
  modelListStream,
} from '../../firestore-mobx/stream'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { safeDeleteStorageObject } from '../../util/safeDeleteStorageObject'
import type { TeachingPlanDetailsPayload } from './types'
import { TeachingPlanUploadType, TeachingPlanStatus } from './types'
import { fetchTeachingPlanModules } from '../TeachingPlanModule'
import {
  deleteTeachingPlanModuleSlideDeck,
  fetchTeachingPlanModuleSlideDecks,
} from '../TeachingPlanModuleSlideDeck'

const converter: FirestoreDataConverter<FirestoreTeachingPlan> = {
  toFirestore: (data: PartialWithFieldValue<FirestoreTeachingPlan>) => {
    writeSchema.partial().parse(data)

    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  params: {
    catalogId: string
  }
): CollectionReference<FirestoreTeachingPlan> => {
  return collection(
    firestore,
    'catalog',
    params.catalogId,
    'teaching_plan'
  ).withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  catalogId: string,
  teachingPlanId: string
): DocumentReference<FirestoreTeachingPlan, DocumentData> => {
  return doc(getColRef(firestore, { catalogId }), teachingPlanId)
}

export const getTeachingPlan = (
  repository: FirebaseRepository,
  { catalogId, teachingPlanId }: { catalogId: string; teachingPlanId: string }
) => {
  const ref = getColRef(repository.firestore, { catalogId })
  const docRef = doc(ref, teachingPlanId)

  return modelItemStream(repository, docRef, TeachingPlan)
}

export const getTeachingPlansForCatalog = (
  repository: FirebaseRepository,
  { catalogId }: { catalogId: string }
) => {
  const ref = getColRef(repository.firestore, { catalogId })

  const catalogIdPredicate = where('catalogId', '==', catalogId)

  const statePredicate = where(
    'teachingPlanStatus',
    '>=',
    TeachingPlanStatus.published
  )

  const q = query(ref, and(catalogIdPredicate, statePredicate))

  return modelListStream(repository, q, TeachingPlan)
}

export const fetchTeachingPlan = async (
  repository: FirebaseRepository,
  { catalogId, teachingPlanId }: { catalogId: string; teachingPlanId: string }
) => {
  const docRef = getDocRef(repository.firestore, catalogId, teachingPlanId)

  const doc = await getDoc(docRef)

  if (!doc.exists())
    throw new Error(`Teaching plan ${teachingPlanId} not found`)

  return convertDocumentSnapshotToModel(repository, doc, TeachingPlan)
}

export const fetchTeachingPlanCount = async (
  repository: FirebaseRepository,
  { catalogId }: { catalogId: string }
) => {
  const ref = getColRef(repository.firestore, { catalogId })

  const catalogIdsPredicate = where('catalogId', 'array-contains', catalogId)
  const statePredicate = where(
    'teachingPlanStatus',
    '>=',
    TeachingPlanStatus.published
  )

  const q = query(ref, and(catalogIdsPredicate, statePredicate))

  const snapshot = await getCountFromServer(q)

  return snapshot.data().count
}

/**
 * Get List of TeachingPlans from Firestore
 * query on teachingPlanState > 0 to avoid
 * soft deleted and initializing teaching plans
 */
export const getTeachingPlans = (
  repository: FirebaseRepository,
  { catalogId }: { catalogId: string }
) => {
  const ref = getColRef(repository.firestore, { catalogId })
  const q = query(
    ref,
    where('teachingPlanStatus', '>=', TeachingPlanStatus.draft)
  )
  return modelListStream(repository, q, TeachingPlan)
}

export const getTeachingPlansById = (
  repository: FirebaseRepository,
  { teachingPlanIds }: { teachingPlanIds: string[] }
) => {
  const collectionGroupRef = collectionGroup(
    repository.firestore,
    'teaching_plan'
  ).withConverter(converter)
  const q = query(
    collectionGroupRef,
    where(documentId(), 'in', teachingPlanIds)
  )
  return modelListStream(repository, q, TeachingPlan)
}

/**
 * Create a new teaching plan in Firestore
 */
export const createTeachingPlan = async (
  repository: FirebaseRepository,
  catalogId: string,
  teachingPlanName: string
): Promise<string> => {
  const colRef = getColRef(repository.firestore, { catalogId })
  const ref = await addDoc(colRef, {
    catalogId: catalogId,
    teachingPlanStatus: TeachingPlanStatus.draft,
    teachingPlanName: teachingPlanName,
    updatedAt: serverTimestamp(),
  })

  return ref.id
}

/**
 * Upload a file for a teaching plan image or video, function uses mime type on the fileData as well
 * as the teachingPlanUploadType to validate and determine the field to be updated with the new URL.
 * the new URL is added to the teaching plan  document as well as being returned from the function.
 *
 * Function will error if the teaching plan document does not exist or the mimeType of the file is
 * invalid for the given teachingPlanUploadType
 */
export const uploadTeachingPlanFile = async (
  repository: FirebaseRepository,
  {
    catalogId,
    teachingPlanId,
    file,
    teachingPlanUploadType,
  }: {
    catalogId: string
    teachingPlanId: string
    file: File
    teachingPlanUploadType: TeachingPlanUploadType
  }
): Promise<string> => {
  // get field and valid extensions from teachingPlanUploadType
  const fields = fieldsWithExtensionsFromTeachingPlanUploadType(
    teachingPlanUploadType
  )
  const fileMimeType = file.type

  const { urlFieldName, extension, mimeType } = fields.teachingPlanUploadDetails

  if (file.type !== fileMimeType) {
    throw new Error(
      `Invalid mime type ${fileMimeType} for teaching plan upload type ${teachingPlanUploadType}`
    )
  }

  const storageRef = ref(
    repository.storage,
    `catalogs/teaching_plan/${teachingPlanId}/${
      teachingPlanUploadType === TeachingPlanUploadType.jpg
        ? 'images'
        : 'videos'
    }/${teachingPlanId}.${extension}`
  )

  await uploadBytes(storageRef, file, {
    contentType: mimeType,
  })

  // get the download url and strip the token param
  const urlWithoutToken = (await getDownloadURL(storageRef)).replaceAll(
    /&token=[a-z0-9-]{36}/g,
    ''
  )

  // add the url to the teaching plan document
  const colRef = getColRef(repository.firestore, { catalogId })
  const teachingPlanRef = doc(colRef, teachingPlanId)

  const teachingPlanUpdatePayload: { [key: string]: string | number } = {
    [urlFieldName]: urlWithoutToken,
  }

  await updateDoc(teachingPlanRef, teachingPlanUpdatePayload)

  return urlWithoutToken
}

// delete a teaching plan file from storage
export const deleteTeachingPlanFile = async (
  repository: FirebaseRepository,
  {
    catalogId,
    teachingPlanId,
    teachingPlanUploadType,
    skipFieldUpdates = false,
  }: {
    catalogId: string
    teachingPlanId: string
    teachingPlanUploadType: TeachingPlanUploadType
    errorOnNotFound?: boolean
    skipFieldUpdates?: boolean
  }
) => {
  const fields = fieldsWithExtensionsFromTeachingPlanUploadType(
    teachingPlanUploadType
  )

  const { urlFieldName, extension } = fields.teachingPlanUploadDetails
  const storageRef = ref(
    repository.storage,
    `catalogs/teaching_plan/${teachingPlanId}/${TeachingPlanUploadType.jpg ? 'images' : 'videos'}/${teachingPlanId}.${extension}`
  )

  await safeDeleteStorageObject(storageRef)

  if (skipFieldUpdates) return

  // update the teaching plan doc, delete the fields
  const deleteFieldValues = {
    [urlFieldName]: deleteField(),
  }

  const colRef = getColRef(repository.firestore, { catalogId })
  const teachingPlanRef = doc(colRef, teachingPlanId)

  await updateDoc(teachingPlanRef, deleteFieldValues)
}

// delete a teaching plan from Firestore
export const deleteTeachingPlan = async (
  repository: FirebaseRepository,
  {
    catalogId,
    teachingPlanId,
  }: {
    catalogId: string
    teachingPlanId: string
  }
) => {
  // make requests to delete the associated files
  const deleteFutures = [
    deleteTeachingPlanFile(repository, {
      catalogId,
      teachingPlanId,
      teachingPlanUploadType: TeachingPlanUploadType.jpg,
      skipFieldUpdates: true,
    }),
    deleteTeachingPlanFile(repository, {
      catalogId,
      teachingPlanId,
      teachingPlanUploadType: TeachingPlanUploadType.mp4,
      skipFieldUpdates: true,
    }),
  ]

  const modules = await fetchTeachingPlanModules(repository, {
    catalogId,
    teachingPlanId,
  })

  // Delete each modules slide decks.
  modules.forEach(async (module) => {
    const slideDecks = await fetchTeachingPlanModuleSlideDecks(repository, {
      catalogId,
      teachingPlanId,
      moduleId: module.id,
    })

    slideDecks.forEach((slideDeck) => {
      deleteFutures.push(
        deleteTeachingPlanModuleSlideDeck(repository, {
          catalogId,
          teachingPlanId,
          moduleId: module.id,
          slideDeckId: slideDeck.id,
        })
      )
    })
  })
  const colRef = getColRef(repository.firestore, { catalogId })
  deleteFutures.push(
    updateDoc(doc(colRef, teachingPlanId), {
      teachingPlanStatus: TeachingPlanStatus.deleted,
      updatedAt: serverTimestamp(),
    })
  )

  await Promise.all(deleteFutures)
}

export const updateTeachingPlanUpdatedAt = async (
  repository: FirebaseRepository,
  catalogId: string,
  teachingPlanId: string
) => {
  const colRef = getColRef(repository.firestore, { catalogId })
  const teachingPlanRef = doc(colRef, teachingPlanId)

  const teachingPlanUpdatePayload = {
    updatedAt: serverTimestamp(),
  }

  return updateDoc(teachingPlanRef, teachingPlanUpdatePayload)
}

export const updateTeachingPlanDetails = async (
  repository: FirebaseRepository,
  catalogId: string,
  teachingPlanId: string,
  params: TeachingPlanDetailsPayload
) => {
  const colRef = getColRef(repository.firestore, { catalogId })
  const teachingPlanRef = doc(colRef, teachingPlanId)

  const teachingPlanUpdatePayload = {
    ...params,
    updatedAt: serverTimestamp(),
  }

  return updateDoc(teachingPlanRef, teachingPlanUpdatePayload)
}

export const changeStatus = async (
  repository: FirebaseRepository,
  catalogId: string,
  teachingPlanId: string,
  status: TeachingPlanStatus
) => {
  const colRef = getColRef(repository.firestore, { catalogId })
  const teachingPlanRef = doc(colRef, teachingPlanId)

  const teachingPlanUpdatePayload = {
    teachingPlanStatus: status,
    updatedAt: serverTimestamp(),
  }

  return updateDoc(teachingPlanRef, teachingPlanUpdatePayload)
}
