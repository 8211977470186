import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeOptionalBoolean,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
  nullSafeString,
} from '../shared/schema'

// this is the dart definition
// required String exhibitDescription, default: ''
// required bool exhibitHasThumbnail, default: false
// required String exhibitName, default: ''
// String? slideId,
// String? exhibitURL,
// int? updatedAt,
export const schema = z.object({
  exhibitDescription: nullSafeString(''),
  exhibitHasThumbnail: nullSafeOptionalBoolean(false),
  exhibitName: nullSafeString(''),
  slideId: nullSafeOptionalString(),
  exhibitURL: nullSafeOptionalString(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
  exhibitURL: z.string().optional(),
  exhibitHasThumbnail: z.boolean().optional(),
})

export const empty = Object.freeze(
  schema.parse({
    exhibitDescription: '',
    exhibitHasThumbnail: false,
    exhibitName: '',
  })
)

export type FirestoreSlideDeckExhibit = z.infer<typeof schema>
export type FirestoreSlideDeckExhibitWrite = z.infer<typeof writeSchema>
