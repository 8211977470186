import type {
  CollectionReference,
  Firestore,
  PartialWithFieldValue,
} from 'firebase/firestore'
import {
  addDoc,
  collection,
  serverTimestamp,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreRoomStateRubricResultFeedback } from './schema'
import { schema, writeSchema } from './schema'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreRoomStateRubricResultFeedback> =
  {
    toFirestore: (
      data: PartialWithFieldValue<FirestoreRoomStateRubricResultFeedback>
    ) => {
      writeSchema.partial().parse(data)

      return data
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot) => {
      const data = snapshot.data({ serverTimestamps: 'estimate' })
      return schema.parse(data)
    },
  }

function getColRef(
  firestore: Firestore,
  roomId: string,
  userId: string,
  rubricId: string
): CollectionReference<FirestoreRoomStateRubricResultFeedback> {
  return collection(
    firestore,
    'room_state',
    roomId,
    'user_rubric_result',
    userId,
    'rubric_result',
    rubricId,
    'rubric_result_feedback'
  ).withConverter(converter)
}

export const submitRubricFeedback = async (
  repository: FirebaseRepository,
  {
    rubricId,
    roomId,
    feedback,
    sectionId,
    userId,
    assignmentId,
  }: {
    rubricId: string
    roomId: string
    feedback: string
    sectionId: string
    userId: string
    assignmentId: string
  }
) => {
  const ref = getColRef(repository.firestore, roomId, userId, rubricId)

  const data = {
    feedback,
    sectionId,
    userId,
    rubricId,
    roomId,
    assignmentId,
    updatedAt: serverTimestamp(),
  }

  await addDoc(ref, data)
}
