import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { Cubit } from './core'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { StaticModelCollection } from '../firestore-mobx/model'
import { Section } from '../models/Section'
import { getSections } from '../firestore/Section'
import { RoomState } from '../models/RoomState'
import { getRoomStatesForStudent } from '../firestore/RoomState'
import { getSlideDeck } from '../firestore/SlideDeck'
import { SlideDeck } from '../models/SlideDeck'
import { SectionAssignment } from '../models/SectionAssignment'
import { AssignmentType } from '../models/SectionAssignment'
import { getSectionAssignments } from '../firestore/SectionAssignment'
import {
  ValidLibraryObject,
  ValidLibraryObjectActionState,
} from '../stores/ValidLibraryObject'
import { LibraryObjectState } from '../types'
import { RoomStateAnswer } from '../models/RoomStateAnswer'
import { SlideQuestion } from '../models/SlideQuestion'
import { fetchRoomStateAnswers } from '../firestore/RoomStateAnswer'
import { fetchSlideQuestions } from '../firestore/SlideQuestion'

export class StudentLibraryCubit extends Cubit {
  repository: FirebaseRepository

  sections: StaticModelCollection<Section>
  roomStates: StaticModelCollection<RoomState>
  slideDecksById = observable.map<string, SlideDeck>()
  assignmentsBySectionId = observable.map<
    string,
    StaticModelCollection<SectionAssignment>
  >()

  userAnswersByRoomId = observable.map<
    string,
    StaticModelCollection<RoomStateAnswer>
  >()

  slideQuestionsBySlideDeckId = observable.map<
    string,
    StaticModelCollection<SlideQuestion>
  >()

  @observable assignmentFilterSearchTerm: string = ''
  @observable assignmentFilter: StudentLibraryAssignmentFilterType = 'all'
  @observable sortingStrategy: StudentLibraryAssignmentSortingStrategy =
    'default'

  @observable sortingOrder: StudentLibraryAssignmentSortingOrder = 'asc'

  // on first load if we want to wait for all userAnswers/slideQuestions to load to avoid flashing various states as they come in
  // afterwards if don't have the data yet we display the next eligible state
  libraryObjectForHeaderDidResolve = false

  // Backwards compatibility for old version of student library
  @observable showCompleted = false

  constructor(repository: FirebaseRepository) {
    super()
    makeObservable(this)

    this.repository = repository

    this.sections = Section.emptyCollection(repository)
    this.roomStates = RoomState.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(
      getSections(this.repository),
      (sections) => {
        this.sections.replaceModels(sections)

        for (const section of sections) {
          this.addSectionAssignmentStream(section.id)
        }
      },
      {
        name: 'sections',
        onError: (error) => {
          console.error('Error getting sections', error)
          this.sections.replaceModels([])
        },
      }
    )

    this.addStream(
      getRoomStatesForStudent(this.repository),
      (roomStates) => {
        // if we need slide questions / answers for any of these, fetch them now, no stream needed

        roomStates.forEach((roomState) => {
          if (!roomState.quizDataRequiredForActionState) return
          this.fetchQuizDataForRoomState(
            roomState.id,
            roomState.data.slideDeckId
          )
        })
        this.roomStates.replaceModels(roomStates)
      },
      {
        name: 'room-states',
        onError: (error) => {
          console.error('Error getting room states', error)
          this.roomStates.replaceModels([])
        },
      }
    )
  }

  addSectionAssignmentStream(sectionId: string): void {
    const key = `section-assignments-${sectionId}`
    this.addStream(
      getSectionAssignments(this.repository, { sectionId }),
      (assignments) => {
        const existing = this.assignmentsBySectionId.get(sectionId)
        if (existing) {
          existing.replaceModels(assignments)
        } else {
          const initial = SectionAssignment.emptyCollection(this.repository)
          initial.replaceModels(assignments)
          runInAction(() => {
            this.assignmentsBySectionId.set(sectionId, initial)
          })
        }

        for (const assignment of assignments) {
          this.addSlideDeckStream(assignment.data.slideDeckId)
        }
      },
      {
        name: key,
        onError: (error) => {
          console.error('Error getting assignments', error)
          this.roomStates.replaceModels([])
          // if it's an error, we want to set it to empty so it still counts as fetched
          const empty = SectionAssignment.emptyCollection(this.repository)
          runInAction(() => {
            this.assignmentsBySectionId.set(sectionId, empty)
          })
        },
      }
    )
  }

  addSlideDeckStream(slideDeckId: string): void {
    const slideDeckKey = `slide-deck-${slideDeckId}`
    // only add slide deck it once
    if (!this.hasStream(slideDeckKey)) {
      // console.log('adding slide deck stream', slideDeckKey)
      this.addStream(
        getSlideDeck(this.repository, { slideDeckId: slideDeckId }),
        (slideDeck) => {
          runInAction(() => {
            this.slideDecksById.set(slideDeck.id, slideDeck)
          })
        },
        {
          name: slideDeckKey,
          onError: (error) => {
            console.error(
              'Error getting slide deck for room state',
              slideDeckId,
              error
            )
            // if it's an error, we want to set it to empty so it still counts
            // and the page can render, even though this slide deck will be missing
            const empty = SlideDeck.empty(this.repository)
            runInAction(() => {
              this.slideDecksById.set(slideDeckId, empty)
            })
          },
        }
      )
    }
  }

  // Backwards compatibility for old version of student library
  @action
  toggleShowCompleted() {
    this.showCompleted = !this.showCompleted
  }

  @action quizDataForRoomState(roomState: RoomState): {
    questions: StaticModelCollection<SlideQuestion>
    answers: StaticModelCollection<RoomStateAnswer>
  } {
    return {
      questions:
        this.slideQuestionsBySlideDeckId.get(roomState.slideDeckId) ||
        SlideQuestion.emptyCollection(this.repository),
      answers:
        this.userAnswersByRoomId.get(roomState.id) ||
        RoomStateAnswer.emptyCollection(this.repository),
    }
  }

  @action
  private fetchQuizDataForRoomState(roomId: string, slideDeckId: string) {
    const userId = this.repository.currentUser?.uid
    // i don't think we can reach this error, mostly here for type safety
    if (!userId) throw new Error('user must be logged in')
    if (!this.userAnswersByRoomId.has(roomId)) {
      // setting to empty collection so we don't fetch again if it's already in progress
      this.userAnswersByRoomId.set(
        roomId,
        RoomStateAnswer.emptyCollection(this.repository)
      )
      fetchRoomStateAnswers(this.repository, { roomId, userId }).then((ans) => {
        this.userAnswersByRoomId.get(roomId)?.replaceModels(ans)
      })
    }
    if (!this.slideQuestionsBySlideDeckId.has(slideDeckId)) {
      // same empty pattern as above
      this.slideQuestionsBySlideDeckId.set(
        slideDeckId,
        SlideQuestion.emptyCollection(this.repository)
      )
      fetchSlideQuestions(this.repository, { slideDeckId }).then(
        (questions) => {
          this.slideQuestionsBySlideDeckId
            .get(slideDeckId)
            ?.replaceModels(questions)
        }
      )
    }
  }

  @computed
  get isLoading() {
    return (
      this.sections.isLoading ||
      this.roomStates.isLoading ||
      this.assignmentsBySectionId.size !== this.sections.models.length
    )
  }

  @computed
  get isLoaded() {
    return !this.isLoading && this.allSlideDecksLoaded
  }

  @computed
  get allSlideDecksLoaded() {
    // all assignments must be loaded to have all slide decks loaded
    if (this.assignmentsBySectionId.size !== this.sections.models.length)
      return false

    const slideDeckIds = Array.from(
      this.assignmentsBySectionId.values()
    ).flatMap((a) => a.models.map((a) => a.data.slideDeckId))

    return slideDeckIds.every((id) => this.slideDecksById.has(id))
  }

  @computed
  get allSectionAssignments() {
    const assignmentsArray = Array.from(this.assignmentsBySectionId.values())
    return assignmentsArray.flatMap((assignments) => assignments.models)
  }

  @computed
  get libraryObjects() {
    const list: ValidLibraryObject[] = []

    for (const assignment of this.allSectionAssignments) {
      const foundSlideDeck = this.slideDecksById.get(
        assignment.data.slideDeckId
      )
      const slideDeck = foundSlideDeck || SlideDeck.empty(this.repository)

      const foundSection = this.sections.models.find(
        (section) => section.id === assignment.data.sectionId
      )
      const section = foundSection || Section.empty(this.repository)

      const roomStates = this.roomStates.models.filter(
        (roomState) => roomState.data.assignmentId === assignment.id
      )
      if (
        roomStates.length === 0 &&
        assignment.data.assignmentType === AssignmentType.studentLed
      ) {
        if (slideDeck) {
          list.push(
            new ValidLibraryObject({
              repository: this.repository,
              section,
              assignment,
              roomState: RoomState.empty(this.repository),
              slideDeck,
            })
          )
        }
      } else {
        for (const roomState of roomStates) {
          list.push(
            new ValidLibraryObject({
              repository: this.repository,
              section,
              assignment,
              roomState,
              slideDeck,
            })
          )
        }
      }
    }

    return list
  }

  @computed
  get libraryObjectForHeader() {
    if (!this.libraryObjectForHeaderDidResolve) {
      const slideQuestionsLoading = Array.from(
        this.slideQuestionsBySlideDeckId.values()
      ).some((questions) => questions.isLoading)
      const userAnswersLoading = Array.from(
        this.userAnswersByRoomId.values()
      ).some((answers) => answers.isLoading)
      if (slideQuestionsLoading || userAnswersLoading) {
        return null
      }
    }
    const actionableLibraryObjects = this.libraryObjects.filter(
      (libraryObj) => {
        const { questions, answers } = this.quizDataForRoomState(
          libraryObj.roomState
        )
        // Disregard if the room is completed or the assignment is expired
        if (
          libraryObj.libraryObjectState === LibraryObjectState.completed ||
          libraryObj.libraryObjectState === LibraryObjectState.expired
        ) {
          return false
        }
        // disregard if don't have data yet
        if (
          libraryObj.roomState.quizDataRequiredForActionState &&
          (answers.isLoading || questions.isLoading)
        ) {
          return false
        }
        // if we have data, check if we can render the action component
        return actionTypeHasActionComponentToRender(
          libraryObj.getActionState(questions.models, answers.models)
        )
      }
    )
    const sorted = actionableLibraryObjects.sort((a, b) => {
      const aActionState = a.getActionState(
        this.quizDataForRoomState(a.roomState).questions.models,
        this.quizDataForRoomState(a.roomState).answers.models
      )
      const bActionState = b.getActionState(
        this.quizDataForRoomState(b.roomState).questions.models,
        this.quizDataForRoomState(b.roomState).answers.models
      )

      const aPriority = priorityFromActionState(aActionState)
      const bPriority = priorityFromActionState(bActionState)

      // if no date provided use maximum possible date
      const aScheduledAt =
        a.roomState.data.scheduledAt || new Date(8640000000000000)
      const bScheduledAt =
        b.roomState.data.scheduledAt || new Date(8640000000000000)

      const aExpiresAt = a.assignment.data.expiresAt
      const bExpiresAt = b.assignment.data.expiresAt

      if (aPriority === bPriority) {
        // we only have priority collisions on on the following state
        // priority === 0 (join/start session)
        // priority === 3 (enroll/scheduleSession)

        // for start/join compare on scheduledTime
        if (aPriority === 0) return compareDates(aScheduledAt, bScheduledAt)
        // for enroll/scheduleSession compare on expiresAt
        if (aPriority === 3) return compareDates(aExpiresAt, bExpiresAt)

        // if we have a priority collision
        // and same dates maintain order
        return 0
      }

      // if priorities are different return the priority with the smaller number
      return aPriority - bPriority
    })

    // return the highest priority actionable object, or null if no actionable objects
    const libraryObjectToReturn = sorted.length ? sorted[0] : null
    if (!libraryObjectToReturn) return null
    this.libraryObjectForHeaderDidResolve = true
    return {
      libraryObject: libraryObjectToReturn,
      actionState: libraryObjectToReturn.getActionState(
        this.quizDataForRoomState(libraryObjectToReturn.roomState).questions
          .models,
        this.quizDataForRoomState(libraryObjectToReturn.roomState).answers
          .models
      ),
    }
  }

  @computed
  get sortedLibraryObjects() {
    const results = this.libraryObjects
      .slice()
      .sort(sortingStrategyFromType(this.sortingStrategy))

    return this.sortingOrder === 'asc' ? results : results.reverse()
  }

  // Backwards compatibility for old version of student library
  @computed
  get filteredLibraryObjects() {
    if (!this.showCompleted) {
      return this.sortedLibraryObjects.filter((libraryObject) => {
        return (
          libraryObject.libraryObjectState !== LibraryObjectState.completed &&
          libraryObject.libraryObjectState !== LibraryObjectState.expired
        )
      })
    }
    return this.sortedLibraryObjects
  }

  @computed
  get filteredSortedLibraryObjects() {
    const strategyFilter = filterStrategyFromType(this.assignmentFilter)
    const filterWithSearchTerm = (libraryObject: ValidLibraryObject) => {
      if (this.assignmentFilterSearchTerm) {
        const {
          slideDeck: {
            data: { slideDeckName, slideDeckTeaser },
          },
          section: {
            data: { className, sectionName },
            instructor: { fullName: instructorName },
          },
        } = libraryObject

        const stringToSearch =
          `${slideDeckName} ${slideDeckTeaser} ${className} ${sectionName} ${instructorName}`.toLowerCase()

        if (
          !stringToSearch.includes(
            this.assignmentFilterSearchTerm.trim().toLowerCase()
          )
        ) {
          return false
        }
        return true
      }
      return strategyFilter(libraryObject)
    }

    return this.sortedLibraryObjects.filter(filterWithSearchTerm)
  }

  @action
  changeAssignmentFilter(filter: StudentLibraryAssignmentFilterType) {
    this.assignmentFilter = filter
  }

  @action
  changeSortingStrategy(
    strategy: StudentLibraryAssignmentSortingStrategy,
    order: StudentLibraryAssignmentSortingOrder
  ) {
    this.sortingStrategy = strategy
    this.sortingOrder = order
  }

  @action
  changeSearchTerm(searchTerm: string) {
    this.assignmentFilterSearchTerm = searchTerm
  }
}

function compareDates(a: Date, b: Date) {
  return a.getTime() - b.getTime()
}

function filterStrategyFromType(
  type: StudentLibraryAssignmentFilterType
): (libraryObject: ValidLibraryObject) => boolean {
  const now = new Date()
  switch (type) {
    case 'all':
      return () => true
    case 'future':
      return (libraryObject) => libraryObject.assignment.data.assignedAt > now
    case 'current':
      return (libraryObject) =>
        libraryObject.assignment.data.assignedAt <= now &&
        libraryObject.assignment.data.expiresAt > now &&
        libraryObject.libraryObjectState !== LibraryObjectState.completed
    case 'past':
      return (libraryObject) =>
        libraryObject.assignment.data.expiresAt <= now ||
        [LibraryObjectState.completed, LibraryObjectState.expired].includes(
          libraryObject.libraryObjectState
        )
  }
}

function sortingStrategyFromType(
  type: StudentLibraryAssignmentSortingStrategy
): (a: ValidLibraryObject, b: ValidLibraryObject) => number {
  switch (type) {
    case 'default':
      return (a, b) => {
        const aState = a.libraryObjectState
        const bState = b.libraryObjectState

        if (aState === bState) {
          if (aState === LibraryObjectState.invited) {
            // sort expiresAt ascending
            return compareDates(
              a.assignment.data.expiresAt,
              b.assignment.data.expiresAt
            )
          } else if (aState === LibraryObjectState.completed) {
            if (
              a.roomState.data.updatedAt === null ||
              b.roomState.data.updatedAt === null
            ) {
              // sort assignedAt descending
              return compareDates(
                b.assignment.data.assignedAt,
                a.assignment.data.assignedAt
              )
            }

            // sort updatedAt descending
            return compareDates(
              b.assignment.data.updatedAt,
              a.assignment.data.updatedAt
            )
          } else {
            // sort assignedAt descending
            return compareDates(
              b.assignment.data.assignedAt,
              a.assignment.data.assignedAt
            )
          }
        } else {
          return aState > bState ? 1 : -1
        }
      }
    case 'sectionName':
      return (a, b) => {
        const aClassName = a.section.data.className
        const aInstructorName = a.section.instructor.fullName

        const bClassName = b.section.data.className
        const bInstructorName = b.section.instructor.fullName

        const classComparison = aClassName.localeCompare(bClassName)
        const instructorComparison =
          aInstructorName.localeCompare(bInstructorName)
        if (classComparison === 0) return instructorComparison
        return classComparison
      }
    case 'experienceName':
      return (a, b) =>
        a.slideDeck.data.slideDeckName.localeCompare(
          b.slideDeck.data.slideDeckName
        )
    case 'expiresAt':
      return (a, b) => {
        return compareDates(
          a.assignment.data.expiresAt,
          b.assignment.data.expiresAt
        )
      }
    case 'scheduledAt':
      return (a, b) => {
        const aScheduled = a.roomState.scheduledAtDate
        const bScheduled = b.roomState.scheduledAtDate
        // if no scheduled put at end
        if (!aScheduled && !bScheduled) return 0
        if (!aScheduled) return 1
        if (!bScheduled) return -1
        return compareDates(aScheduled.toJSDate(), bScheduled.toJSDate())
      }
  }
}

export type StudentLibraryAssignmentFilterType =
  | 'all'
  | 'future'
  | 'current'
  | 'past'

export type StudentLibraryAssignmentSortingStrategy =
  | 'default'
  | 'sectionName'
  | 'experienceName'
  | 'expiresAt'
  | 'scheduledAt'

export type StudentLibraryAssignmentSortingOrder = 'asc' | 'desc'

/**
 * We have an action type for every LibraryObjectState
 * some have buttons for navigation and some do not
 *
 * we use a switch here for type safety if we add more states
 */
function actionTypeHasActionComponentToRender(
  actionState: ValidLibraryObjectActionState
): boolean {
  switch (actionState) {
    case ValidLibraryObjectActionState.enroll:
      return true
    case ValidLibraryObjectActionState.availableOn:
      return false
    case ValidLibraryObjectActionState.joinGroup:
      return true
    case ValidLibraryObjectActionState.scheduleSession:
      return true
    case ValidLibraryObjectActionState.completeQuiz:
      return true
    case ValidLibraryObjectActionState.sessionScheduled:
      return false
    case ValidLibraryObjectActionState.pending:
      return false
    case ValidLibraryObjectActionState.joinSession:
      return true
    case ValidLibraryObjectActionState.startSession:
      return true
    // view results has a action component but we don't want to render it in header ever afaik
    case ValidLibraryObjectActionState.viewResults:
      return false
    case ValidLibraryObjectActionState.experienceExpired:
      return false
  }
}

// returns number to be used for sorting
// where we return infinity we don't expect to have those in the filtered list
function priorityFromActionState(
  actionState: ValidLibraryObjectActionState
): number {
  switch (actionState) {
    case ValidLibraryObjectActionState.enroll:
      return 3
    case ValidLibraryObjectActionState.availableOn:
      return Number.POSITIVE_INFINITY
    case ValidLibraryObjectActionState.joinGroup:
      return 2
    // not sure if we can hit this state in practice
    // give same priority as enroll and then date sort will take over
    case ValidLibraryObjectActionState.scheduleSession:
      return 3
    case ValidLibraryObjectActionState.completeQuiz:
      return 1
    case ValidLibraryObjectActionState.sessionScheduled:
      return Number.POSITIVE_INFINITY
    case ValidLibraryObjectActionState.pending:
      return Number.POSITIVE_INFINITY
    // join and start session are the same priority
    case ValidLibraryObjectActionState.joinSession:
      return 0
    case ValidLibraryObjectActionState.startSession:
      return 0
    // view results has a action component but we don't want to render it in header ever afaik
    case ValidLibraryObjectActionState.viewResults:
      return Number.POSITIVE_INFINITY
    case ValidLibraryObjectActionState.experienceExpired:
      return Number.POSITIVE_INFINITY
  }
}
