import { computed, makeObservable } from 'mobx'

import type { DateTime } from 'luxon'
import {
  createSection,
  fetchInstructorSections,
  fetchSectionsForInstructors,
  getSectionsStreamForInstructor,
  getSectionsStreamForInstructors,
  SectionState,
} from '../firestore/Section'
import { createSectionAssignment } from '../firestore/SectionAssignment'
import { getSlideDeck } from '../firestore/SlideDeck'
import { fetchSlideDeckMaterialsForInstructor } from '../firestore/SlideDeckMaterial'
import { fetchSlideQuestions } from '../firestore/SlideQuestion'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import type {
  AssignmentGroupingType,
  AssignmentType,
} from '../models/SectionAssignment'
import { SlideDeck } from '../models/SlideDeck'
import { SlideDeckMaterial } from '../models/SlideDeckMaterial'
import { SlideQuestion } from '../models/SlideQuestion'
import { UserProfileRole, type StaticModelCollection } from '../types'
import { Cubit } from './core'
import { captureException } from '@sentry/core'
import { fetchTAInstructorIDs } from '../firestore/UserProfile'
import { fetchCatalogsAccessibleByUsers } from '../firestore/Catalog'
import { Section } from '../models/Section'
import { fetchSlides } from '../firestore/Slide'
import { SlideModel } from '../models/SlideModel'
import { SlideDeckAuthor } from '../models/SlideDeckAuthor'
import { getSlideDeckAuthors } from '../firestore/SlideDeckAuthor'
import { SlideRubric } from '../models/SlideRubric'
import { getSlideRubrics } from '../firestore/SlideRubric'
import { SlideDeckReference } from '../models/SlideDeckReference'
import { getSlideDeckReferences } from '../firestore/SlideDeckReference'
import type { UserPromotion } from '../models/UserPromotion'
import { redeemPromotions } from '../firestore/UserPromotionRedemption'
export class InstructorSlideDeckCubit extends Cubit {
  repository: FirebaseRepository

  slideDeckId: string

  slideDeck: SlideDeck
  slides: StaticModelCollection<SlideModel>
  questions: StaticModelCollection<SlideQuestion>
  materials: StaticModelCollection<SlideDeckMaterial>
  authors: StaticModelCollection<SlideDeckAuthor>
  rubrics: StaticModelCollection<SlideRubric>
  references: StaticModelCollection<SlideDeckReference>
  sections: StaticModelCollection<Section>

  instructorUserId?: string
  catalogId?: string

  constructor(
    repository: FirebaseRepository,
    slideDeckId: string,
    instructorUserId?: string,
    catalogId?: string
  ) {
    super()
    this.slideDeckId = slideDeckId
    this.instructorUserId = instructorUserId
    this.catalogId = catalogId
    makeObservable(this)

    this.repository = repository
    this.slideDeck = SlideDeck.empty(repository)
    this.slides = SlideModel.emptyCollection(repository)
    this.questions = SlideQuestion.emptyCollection(repository)
    this.materials = SlideDeckMaterial.emptyCollection(repository)
    this.authors = SlideDeckAuthor.emptyCollection(repository)
    this.rubrics = SlideRubric.emptyCollection(repository)
    this.references = SlideDeckReference.emptyCollection(repository)
    this.sections = Section.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(
      getSlideDeck(this.repository, { slideDeckId: this.slideDeckId }),
      (slideDeck) => {
        this.slideDeck.replaceModel(slideDeck)
      }
    )
    this.addStream(
      getSlideDeckAuthors(this.repository, { slideDeckId: this.slideDeckId }),
      (authors) => {
        this.authors.replaceModels(authors)
      }
    )

    this.addStream(
      getSlideRubrics(this.repository, { slideDeckId: this.slideDeckId }),
      (rubrics) => {
        this.rubrics.replaceModels(rubrics)
      }
    )

    this.addStream(
      getSlideDeckReferences(this.repository, {
        slideDeckId: this.slideDeckId,
      }),
      (references) => {
        this.references.replaceModels(references)
      }
    )

    const user = this.repository.breakoutUser

    this.fetchQuestions()
    this.fetchMaterials()
    this.fetchSlides()

    if (user?.role === UserProfileRole.ta) {
      this.startSectionsStreamForTA()
    } else {
      this.addStream(
        getSectionsStreamForInstructor(this.repository, {
          instructorUserId: this.instructorUserId,
        }),
        (sections) => {
          // filter our closed sections
          sections = sections.filter(
            (section) => section.data.sectionState !== SectionState.completed
          )
          this.sections.replaceModels(sections)
        }
      )
    }
  }

  @computed
  get questionsSorted() {
    const slideIds = this.slidesSorted.map((slide) => slide.id)
    return this.questions.models.sort((a, b) => {
      const aIndex = slideIds.indexOf(a.data.slideId ?? '')
      const bIndex = slideIds.indexOf(b.data.slideId ?? '')
      return aIndex - bIndex || a.data.question.localeCompare(b.data.question)
    })
  }

  @computed
  get slidesSorted() {
    return this.slides.models.sort(
      (a, b) => a.data.slideOrder - b.data.slideOrder
    )
  }

  sortSections = (sections: Section[]) => {
    return sections.sort((a: Section, b: Section) => {
      if (a.data.sectionState === b.data.sectionState) {
        if (
          a.data.updatedAt.getMilliseconds() ===
          b.data.updatedAt.getMilliseconds()
        ) {
          if (a.data.className !== b.data.className) {
            return a.data.className.localeCompare(b.data.className)
          } else {
            return a.data.sectionName.localeCompare(b.data.sectionName)
          }
        }
        return a.data.updatedAt!.getTime() - b.data.updatedAt!.getTime()
      }
      return a.data.sectionState - b.data.sectionState
    })
  }

  async fetchSlides() {
    try {
      const slides = await fetchSlides(this.repository, {
        slideDeckId: this.slideDeckId,
      })
      this.slides.replaceModels(slides)
    } catch (error) {
      captureException(error)
      this.slides.replaceModels([])
    }
  }

  async fetchMaterials() {
    try {
      const data = await fetchSlideDeckMaterialsForInstructor(this.repository, {
        slideDeckId: this.slideDeckId,
      })
      this.materials.replaceModels(data)
    } catch (error) {
      captureException(error)
      this.materials.replaceModels([])
    }
  }

  async fetchQuestions() {
    try {
      const data = await fetchSlideQuestions(this.repository, {
        slideDeckId: this.slideDeckId,
      })

      this.questions.replaceModels(data)
    } catch (error) {
      captureException(error)
      this.questions.replaceModels([])
    }
  }

  async fetchSections(
    catalogId: string,
    role: string,
    instructorUserId?: string
  ) {
    if (role !== UserProfileRole.ta) {
      return fetchInstructorSections(this.repository, { instructorUserId })
    }

    /* TA only logic */

    // get instructors which can access the catalog
    const instructorIds = await fetchTAInstructorIDs(this.repository)

    // get catalogs accessible by the instructors
    const catalogsAccessibleByInstructorId =
      await fetchCatalogsAccessibleByUsers(this.repository, {
        userIds: instructorIds,
      })

    // prune instructors which can not access the current catalog
    const instructorsWithCatalogAccess = instructorIds.filter(
      (instructorId) => {
        const instructorCatalogs =
          instructorId in catalogsAccessibleByInstructorId
            ? catalogsAccessibleByInstructorId[instructorId]
            : []

        // todo(ashold12): the dart app renders all TA sections, however this is potentially incorrect as all instructors may not have
        // access to the current catalog. Here I am pruning sections of profs which do not have access to the current catalog.
        // however, if they have no catalogs I am assuming they are an admin and rendering anyways. This is not perfect
        // but I believe it's an improvement over the previous implementation as we can not interrogate the user's role
        return (
          instructorCatalogs.includes(catalogId) ||
          instructorCatalogs.length === 0
        )
      }
    )
    // fetch sections for the pruned instructors
    return await fetchSectionsForInstructors(this.repository, {
      instructorIds: instructorsWithCatalogAccess,
    })
  }

  async startSectionsStreamForTA() {
    const catalogId = this.catalogId

    if (!catalogId) return

    /* TA only logic */
    // get instructors which can access the catalog
    const instructorIds = await fetchTAInstructorIDs(this.repository)

    // get catalogs accessible by the instructors
    const catalogsAccessibleByInstructorId =
      await fetchCatalogsAccessibleByUsers(this.repository, {
        userIds: instructorIds,
      })

    // prune instructors which can not access the current catalog
    const instructorsWithCatalogAccess = instructorIds.filter(
      (instructorId) => {
        const instructorCatalogs =
          instructorId in catalogsAccessibleByInstructorId
            ? catalogsAccessibleByInstructorId[instructorId]
            : []

        // todo(ashold12): the dart app renders all TA sections, however this is potentially incorrect as all instructors may not have
        // access to the current catalog. Here I am pruning sections of profs which do not have access to the current catalog.
        // however, if they have no catalogs I am assuming they are an admin and rendering anyways. This is not perfect
        // but I believe it's an improvement over the previous implementation as we can not interrogate the user's role
        return (
          instructorCatalogs.includes(catalogId) ||
          instructorCatalogs.length === 0
        )
      }
    )
    const stream = getSectionsStreamForInstructors(
      this.repository,
      instructorsWithCatalogAccess
    )

    this.addStream(stream, (sections) => {
      // filter our closed sections
      sections = sections.filter(
        (section) => section.data.sectionState !== SectionState.completed
      )
      this.sections.replaceModels(sections)
    })
  }

  async createSectionAssignment(params: {
    sectionId: string
    assignmentType: AssignmentType
    assignedAt: DateTime
    expiresAt: DateTime
    groupingType: AssignmentGroupingType
    slideDeckId: string
    catalogId?: string
    groupingSize?: number
  }) {
    return createSectionAssignment(this.repository, params)
  }

  createSection = (className: string, sectionName: string) => {
    return createSection(this.repository, {
      className,
      sectionName,
      userId: this.instructorUserId || this.repository.uid,
    })
  }

  redeemPromotions = (sectionId: string, userPromotions: UserPromotion[]) => {
    return Promise.all(
      userPromotions.map((userPromotion) =>
        redeemPromotions(this.repository, {
          userId: this.repository.breakoutUser!.uid,
          promotionId: userPromotion.data.promotionId,
          userPromotionId: userPromotion.id,
          sectionId,
        })
      )
    )
  }
}
