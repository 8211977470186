import { action, computed, makeObservable, observable } from 'mobx'

import { SlideDeck } from '../models/SlideDeck'

export class SlideDeckGroup {
  slideDecks = observable.array<SlideDeck>([])

  constructor(
    public parentId: string,
    private useLatestPublishedForMainDeck = false
  ) {
    makeObservable(this)
  }

  @action
  addSlideDeck(slideDeck: SlideDeck) {
    this.slideDecks.push(slideDeck)
  }

  @computed
  get sortedSlideDecks() {
    return this.slideDecks.concat().sort((a, b) => {
      return b.data.updatedAt.getTime() - a.data.updatedAt.getTime()
    })
  }

  @computed
  get mainDeck() {
    if (!this.sortedSlideDecks.length) return undefined
    // use the latest published if there is one, or else the most recently modified
    if (this.useLatestPublishedForMainDeck) {
      return this.latestDeck
    }
    return this.slideDecks.find((slideDeck) => slideDeck.id === this.parentId)
  }

  @computed
  get latestDeck() {
    if (!this.sortedSlideDecks.length) return undefined
    return (
      this.sortedSlideDecks.find((slideDeck) => slideDeck.isPublished) ||
      this.sortedSlideDecks[0]
    )
  }

  @computed
  get imageUrl() {
    if (!this.mainDeck) return ''
    return this.mainDeck.data.slideDeckImageURL || ''
  }

  @computed
  get name() {
    if (!this.mainDeck) return ''
    return this.mainDeck.data.slideDeckName
  }

  @computed
  get teaser() {
    if (!this.mainDeck) return ''
    return this.mainDeck.data.slideDeckTeaser
  }

  @computed
  get version() {
    if (!this.mainDeck) return ''
    return this.mainDeck.data.slideDeckVersion
  }

  @computed
  get updatedAt() {
    const latest = this.slideDecks.concat().sort((a, b) => {
      return b.data.updatedAt.getTime() - a.data.updatedAt.getTime()
    })[0]

    if (latest) return latest.data.updatedAt
    return new Date()
  }
}
