import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type {
  FieldValue,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  collection,
  doc,
  orderBy,
  query,
  serverTimestamp,
  type Firestore,
  CollectionReference,
  addDoc,
  updateDoc,
  deleteField,
  deleteDoc,
  writeBatch,
  getDocs,
} from 'firebase/firestore'
import type { FirestoreSlideDeckAuthor } from './schema'
import { slideDeckAuthorSchema } from './schema'
import { modelListStream } from '../../firestore-mobx/stream'
import { SlideDeckAuthor } from '../../models/SlideDeckAuthor'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { safeDeleteStorageObject } from '../../util/safeDeleteStorageObject'

const converter: FirestoreDataConverter<FirestoreSlideDeckAuthor> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return slideDeckAuthorSchema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  params: {
    slideDeckId: string
  }
): CollectionReference<FirestoreSlideDeckAuthor> => {
  return collection(
    firestore,
    'slide_deck',
    params.slideDeckId,
    'author'
  ).withConverter(converter)
}

export const getSlideDeckAuthors = (
  repository: FirebaseRepository,
  params: {
    slideDeckId: string
  }
) => {
  const colRef = getColRef(repository.firestore, {
    slideDeckId: params.slideDeckId,
  })
  const q = query(colRef, orderBy('order'))
  return modelListStream(repository, q, SlideDeckAuthor)
}

export type AuthorFieldsForUpload = Omit<
  FirestoreSlideDeckAuthor,
  'updatedAt' | 'order'
>

export const saveSlideDeckAuthor = async (
  repository: FirebaseRepository,
  {
    authorFields,
    slideDeckId,
    authorId,
  }: {
    slideDeckId: string
    authorFields: AuthorFieldsForUpload
    authorId?: string
  }
) => {
  interface WriteInterface extends Omit<FirestoreSlideDeckAuthor, 'updatedAt'> {
    updatedAt: FieldValue
  }

  const dataToWrite: WriteInterface = {
    ...authorFields,
    updatedAt: serverTimestamp(),
  }

  if (authorId === undefined) {
    dataToWrite.order = 999
  }

  const ref = authorId
    ? doc(getColRef(repository.firestore, { slideDeckId }), authorId)
    : getColRef(repository.firestore, { slideDeckId })

  if (ref instanceof CollectionReference) {
    const newAuthor = await addDoc(ref, dataToWrite)
    reorderSlideDeckAuthors(repository, slideDeckId)
    return newAuthor
  }

  //@ts-expect-error - todo: not sure why the type system hates this
  return await updateDoc(ref, dataToWrite)
}

const reorderSlideDeckAuthors = async (
  repository: FirebaseRepository,
  slideDeckId: string
) => {
  const colRef = getColRef(repository.firestore, {
    slideDeckId: slideDeckId,
  })

  // Query to order by order
  const q = query(colRef, orderBy('order'))
  const docs = await getDocs(q)
  const batch = writeBatch(repository.firestore)

  // Sort documents locally by order and then by updatedAt
  const sortedDocs = docs.docs.sort((a, b) => {
    const aData = a.data()
    const bData = b.data()

    const aOrder = aData.order !== undefined ? aData.order : 0
    const bOrder = bData.order !== undefined ? bData.order : 0

    if (aOrder === bOrder) {
      const aUpdatedAt = aData.updatedAt
        ? new Date(aData.updatedAt).getTime()
        : 0
      const bUpdatedAt = bData.updatedAt
        ? new Date(bData.updatedAt).getTime()
        : 0
      return aUpdatedAt - bUpdatedAt
    } else {
      return aOrder - bOrder
    }
  })

  // Reorder based on the locally sorted documents
  sortedDocs.forEach((doc, index) => {
    if (doc.data().order === index) return
    batch.update(doc.ref, { order: index })
  })

  await batch.commit()
}

export const uploadSlideDeckAuthorImage = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    authorId,
    file,
  }: {
    slideDeckId: string
    authorId: string
    file: File
  }
) => {
  const fileMimeType = file.type

  const storageRef = ref(
    repository.storage,
    `slide_deck/${slideDeckId}/author/${authorId}`
  )

  await uploadBytes(storageRef, file, {
    contentType: fileMimeType,
  })

  const urlWithoutToken = (await getDownloadURL(storageRef)).replaceAll(
    /&token=[a-z0-9-]{36}/g,
    ''
  )

  const authorRef = doc(
    getColRef(repository.firestore, { slideDeckId }),
    authorId
  )

  const authorUpdatePayload = {
    authorImageURL: urlWithoutToken,
  }

  await updateDoc(authorRef, authorUpdatePayload)

  return urlWithoutToken
}

export const deleteSlideDeckAuthor = async (
  repository: FirebaseRepository,
  {
    authorId,
    slideDeckId,
  }: {
    authorId: string
    slideDeckId: string
  }
) => {
  const deletePromises = [
    deleteSlideDeckAuthorImage(repository, {
      slideDeckId,
      authorId,
    }),
    deleteDoc(doc(getColRef(repository.firestore, { slideDeckId }), authorId)),
  ]

  await Promise.all(deletePromises)
}

export const deleteSlideDeckAuthorImage = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    authorId,
    skipFieldUpdates = false,
  }: {
    slideDeckId: string
    authorId: string
    skipFieldUpdates?: boolean
  }
) => {
  const storageRef = ref(
    repository.storage,
    `slide_deck/${slideDeckId}/author/${authorId}`
  )

  await safeDeleteStorageObject(storageRef)

  if (skipFieldUpdates) return

  // update the material doc, delete the fields
  const deleteFieldValues = {
    authorImageURL: deleteField(),
  }

  const authorRef = doc(
    getColRef(repository.firestore, { slideDeckId }),
    authorId
  )

  await updateDoc(authorRef, deleteFieldValues)
}

/**
 * Sort the authors in the slide_deck by the order field and update
 * the order field with the index of the author in the list so that
 * the order is always correct and the authors are always sorted by
 * the order field when the slide_deck is loaded from Firestore.
 *
 * if **oldIndex** and **newIndex** are supplied then move element at **oldIndex** to **newIndex**
 */

export const sortSlideDeckAuthors = async (
  repository: FirebaseRepository,
  {
    currentOrder,
    oldIndex,
    newIndex,
    slideDeckId,
  }: {
    slideDeckId: string
    currentOrder: string[]
    oldIndex?: number
    newIndex?: number
  }
) => {
  const authorIds = [...currentOrder]

  // if old index and new index supplied then reorder
  if (oldIndex !== undefined && newIndex !== undefined) {
    //remove at old index
    const targetId = authorIds.splice(oldIndex, 1)[0]

    //insert at new index
    authorIds.splice(newIndex, 0, targetId)
  }

  const batch = writeBatch(repository.firestore)
  authorIds.forEach((authorId, index) => {
    // get the author doc ref
    const authorRef = doc(
      getColRef(repository.firestore, { slideDeckId }),
      authorId
    )
    batch.update(authorRef, { order: index })
  })

  // perform the batch write
  await batch.commit()
}
