import type { MobxDocument } from '../firestore-mobx'
import type { FirebaseRepository } from './FirebaseRepository'
import { computed, makeObservable } from 'mobx'
import { empty, type FirestoreSection } from '../firestore/Section/schema'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'

// TODO: rename back to Section when the Section type is removed
export class Section extends ObservableModelWithDecorators<FirestoreSection> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSection>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  @computed
  get allUsers() {
    return this.repository.userStore.getUsers([])
  }

  @computed
  get students() {
    return this.repository.userStore.getUsers(this.data.userIds)
  }

  @computed
  get studentsLoaded() {
    if (this.data.userIds.length === 0) return true

    return this.students.every((user) => user.isLoaded)
  }

  @computed
  get userCount() {
    return this.data.userIds.length
  }

  @computed
  get instructor() {
    return this.repository.userStore.getUser(this.data.instructorUserId)
  }

  @computed
  get sectionLabelWithClass() {
    return `${this.data.className}: ${this.data.sectionName}`
  }
}
