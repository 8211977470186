import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeOptionalBoolean,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeString,
  nullSafeStringArray,
} from '../shared/schema'
import { BloomScore, PassFailScore } from '../../types'

const bloomsScore = z.nativeEnum(BloomScore)
const passFailScore = z.nativeEnum(PassFailScore)
const score = z.union([bloomsScore, passFailScore])

export const schema = z.object({
  arguments: nullSafeStringArray([]),
  currentTest: nullSafeOptionalBoolean(),
  justification: nullSafeString(''),
  rubricId: z.string(),
  rubricPrompt: nullSafeString(''),
  rubricTestScript: nullSafeString(''),
  score: z.preprocess((val) => val ?? BloomScore.none, score),
  rubricTestId: z.string().nullable().optional(),
  systemPrompt: nullSafeString(''),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    arguments: [],
    currentTest: false,
    justification: '',
    rubricId: '',
    rubricPrompt: '',
    rubricTestScript: '',
    score: BloomScore.none,
    rubricTestId: null,
    systemPrompt: '',
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export type FirestoreSlideDeckRubricTestResult = z.infer<typeof schema>
