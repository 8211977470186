import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  PartialWithFieldValue,
  Query,
} from 'firebase/firestore'
import {
  and,
  collection,
  collectionGroup,
  doc,
  getDocs,
  query,
  where,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { StaticModelCollection } from '../../firestore-mobx/model'
import {
  convertDocumentSnapshotToModel,
  modelListStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { RoomStateEngagement } from '../../models/RoomStateEngagement'
import type { FirestoreRoomStateEngagement } from './schema'
import { empty, schema, writeSchema } from './schema'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreRoomStateEngagement> = {
  toFirestore: (data: PartialWithFieldValue<FirestoreRoomStateEngagement>) => {
    writeSchema.partial().parse(data)

    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  { roomId }: { roomId: string }
): CollectionReference<FirestoreRoomStateEngagement> => {
  const roomStateRef = doc(collection(firestore, 'room_state'), roomId)
  const engagementCollectionRef = collection(
    roomStateRef,
    'engagement'
  ).withConverter(converter)
  return engagementCollectionRef
}

// @ts-expect-error - unused for now
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getDocRef = (
  firestore: Firestore,
  roomId: string
): DocumentReference<FirestoreRoomStateEngagement, DocumentData> => {
  return doc(getColRef(firestore, { roomId }), roomId)
}

function getColGroupRef(
  firestore: Firestore
): Query<FirestoreRoomStateEngagement> {
  return collectionGroup(firestore, 'engagement').withConverter(converter)
}

export const buildEmptyRoomStateEngagementResultCollection = (
  repository: FirebaseRepository
) => {
  return new StaticModelCollection({
    repository,
    model: RoomStateEngagement,
    empty: empty,
  })
}

export const getRoomStateEngagementStreamForAssignment = (
  repository: FirebaseRepository,
  { sectionId, assignmentId }: { sectionId: string; assignmentId: string }
) => {
  const q = query(
    getColGroupRef(repository.firestore),
    and(
      where('sectionId', '==', sectionId),
      where('assignmentId', '==', assignmentId)
    )
  )

  return modelListStream(repository, q, RoomStateEngagement)
}

export const getRoomStateEngagementStreamForRoomState = (
  repository: FirebaseRepository,
  { roomId }: { roomId: string }
) => {
  const q = query(getColRef(repository.firestore, { roomId }))

  return modelListStream(repository, q, RoomStateEngagement)
}

export const fetchRoomStateEngagementForRoomState = async (
  repository: FirebaseRepository,
  { roomId }: { roomId: string }
) => {
  const { docs } = await getDocs(
    query(getColRef(repository.firestore, { roomId }))
  )
  return docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, RoomStateEngagement)
  })
}
