import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
} from 'firebase/firestore'
import {
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  documentId,
  getDocs,
  limit,
  query,
  startAfter,
  where,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { StreamInterface } from 'tricklejs/dist/types'
import { ZodError } from 'zod'
import type {
  ObservableModel,
  ObservableModelCollection,
} from '../../firestore-mobx/model'
import {
  CollectionSnapshotStreamCollector,
  collectionSnapshots,
  convertDocumentSnapshotToModel,
  modelItemStream,
  modelListStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { PublicUser } from '../../models/PublicUser'
import { AppUser } from '../../stores/AppUser'
import { partition, unique } from '../../util/arrays'
import {
  fetchAllUserProfiles,
  fetchAllUserProfilesByEmail,
} from '../UserProfile'
import { UserProfileRole } from '../UserProfile/types'
import type { FirestorePublicUser } from './schema'
import { schema } from './schema'

export * from './schema'

export interface PublicUserObservableModel
  extends ObservableModel<FirestorePublicUser> {}

export interface PublicUserObservableModelCollection
  extends ObservableModelCollection<PublicUser, FirestorePublicUser> {}

const maxSearchableUserIds = 15

const converter: FirestoreDataConverter<FirestorePublicUser> = {
  toFirestore: () => {
    throw new Error('Client cannot write FirestorePublicUser')
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    try {
      return schema.parse(data)
    } catch (e) {
      if (e instanceof ZodError) {
        console.error('Error PublicUser', e.errors)
      }
      throw e
    }
  },
}

const baseCollectionRef = (
  firestore: Firestore
): CollectionReference<FirestorePublicUser, DocumentData> => {
  return collection(firestore, 'users').withConverter(converter)
}

const docRef = (
  firestore: Firestore,
  userId: string
): DocumentReference<FirestorePublicUser, DocumentData> => {
  return doc(baseCollectionRef(firestore), userId)
}

export const fetchUsers = async (
  repository: FirebaseRepository,
  userIds: string[]
) => {
  const uniqueUserIds = unique(userIds)
  if (uniqueUserIds.length === 0) return []

  const ref = baseCollectionRef(repository.firestore)
  const q = query(ref, where(documentId(), 'in', uniqueUserIds))

  const response = await getDocs(q)

  return response.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, PublicUser)
  })
}

export const getPublicUser = (
  repository: FirebaseRepository,
  params: { userId: string }
): StreamInterface<PublicUser> => {
  const ref = docRef(repository.firestore, params.userId)

  return modelItemStream(repository, ref, PublicUser)
}

export const getPublicUsers = (
  repository: FirebaseRepository,
  params: { userIds: string[] }
): StreamInterface<PublicUser[]> => {
  const ref = baseCollectionRef(repository.firestore)
  // filter out empty strings
  const userIds = unique(params.userIds).filter((id) => id)

  if (userIds.length > maxSearchableUserIds) {
    const collector = new CollectionSnapshotStreamCollector<PublicUser>()

    const partitions = partition(userIds, maxSearchableUserIds)

    partitions.forEach((part) => {
      const q = query(ref, where(documentId(), 'in', part))
      const stream = modelListStream(repository, q, PublicUser)
      collector.attachStream(stream)
    })

    return collector.stream
  }

  const q = query(ref, where(documentId(), 'in', userIds))

  return modelListStream(repository, q, PublicUser)
}

export const getMyAssistants = (
  repository: FirebaseRepository,
  { instructorUserId }: { instructorUserId?: string } = {}
): StreamInterface<PublicUser[]> => {
  const colGroupRef = collectionGroup(repository.firestore, 'instructor')
  const q = query(
    colGroupRef,
    where('instructorUserId', '==', instructorUserId || repository.uid)
  )

  return collectionSnapshots(q).map((snapshot) => {
    return snapshot.docs.map<PublicUser>((doc) => {
      const userId = doc.data().userId as string
      return repository.userStore.getUser(userId)
    })
  })
}

export const deleteTARecordFromAppUser = (
  firestore: Firestore,
  params: { userId: string; instructorUserId: string }
) => {
  const colRef = collection(
    firestore,
    'user_profile',
    params.userId,
    'instructor'
  )
  const docRef = doc(colRef, params.instructorUserId)

  return deleteDoc(docRef)
}

export const getUsers = (
  repository: FirebaseRepository,
  { userIds }: { userIds: string[] }
) => {
  const ref = baseCollectionRef(repository.firestore)
  const q = query(ref, where(documentId(), 'in', userIds))
  return modelListStream(repository, q, PublicUser)
}

export const fetchPublicUsers = (
  repository: FirebaseRepository,
  params: { userIds: string[] }
): Promise<PublicUser[]> => {
  const ref = baseCollectionRef(repository.firestore)
  // filter out empty strings
  const userIds = unique(params.userIds).filter((id) => id)

  if (userIds.length === 0) return Promise.resolve([])

  const promises: Promise<PublicUser[]>[] = []

  if (userIds.length > maxSearchableUserIds) {
    const partitions = partition(userIds, maxSearchableUserIds)

    partitions.forEach((part) => {
      const promise = new Promise<PublicUser[]>((resolve) => {
        const q = query(ref, where(documentId(), 'in', part))
        getDocs(q).then((snapshot) => {
          const converted = snapshot.docs.map((doc) => {
            return convertDocumentSnapshotToModel(repository, doc, PublicUser)
          })

          resolve(converted)
        })
      })
      promises.push(promise)
    })
  } else {
    const q = query(ref, where(documentId(), 'in', userIds))
    const promise = getDocs(q).then((snapshot) => {
      return snapshot.docs.map((doc) => {
        return convertDocumentSnapshotToModel(repository, doc, PublicUser)
      })
    })
    promises.push(promise)
  }

  return Promise.all(promises).then((results) => {
    return results.flat()
  })
}

export async function fetchAllUsers(repository: FirebaseRepository) {
  const users: PublicUser[] = []

  const documentLimit = 100
  let lastDocument: QueryDocumentSnapshot | undefined

  let work = true
  while (work) {
    let queryRef = query(
      baseCollectionRef(repository.firestore),
      limit(documentLimit)
    )

    if (lastDocument) {
      queryRef = query(queryRef, startAfter(lastDocument))
    }

    const querySnapshot = await getDocs(queryRef)
    const docs = querySnapshot.docs

    if (docs.length === 0) {
      work = false
    } else {
      const converted = docs.map((doc) => {
        return convertDocumentSnapshotToModel(repository, doc, PublicUser)
      })
      users.push(...converted)
      lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1]
    }
  }

  return users
}

export async function fetchAllAppUsersByRole(
  repository: FirebaseRepository,
  role: UserProfileRole
) {
  const appUsers: AppUser[] = []

  const profiles = await fetchAllUserProfiles(repository, role)

  const userIds = profiles.map((profile) => profile.id)

  const users = await fetchPublicUsers(repository, { userIds })

  const userLookup = new Map(users.map((user) => [user.id, user]))

  profiles.forEach((profile) => {
    const user = userLookup.get(profile.id)
    if (user) {
      const appUser = new AppUser(repository, user, profile)
      appUsers.push(appUser)
    }
  })

  return appUsers
}

export async function fetchAllAppUsersStudents(
  repository: FirebaseRepository,
  filter: string
) {
  const appUsers: AppUser[] = []

  const profiles = await fetchAllUserProfilesByEmail(
    repository,
    UserProfileRole.student,
    filter
  )

  const userIds = profiles.map((profile) => profile.id)

  const users = await fetchPublicUsers(repository, { userIds })

  const userLookup = new Map(users.map((user) => [user.id, user]))

  profiles.forEach((profile) => {
    if (profile.isAnonymous) {
      return
    }
    const user = userLookup.get(profile.id)
    if (user) {
      const appUser = new AppUser(repository, user, profile)
      appUsers.push(appUser)
    }
  })

  return appUsers
}

// getAllAppUsersByRole(
//   FirebaseFirestore firestore,
//   String role,
// ) async {
//   if (role.isEmpty) {
//     return [];
//   }
//   var publicUsersList = <PublicUser>[];
//   if (role == UserProfileRole.student) {
//     publicUsersList = await getAllUsers(firestore);
//   }
//   const documentLimit = 100;
//   DocumentSnapshot? lastDocument;
//   final allDocumentsList = <AppUser>[];

//   while (true) {
//     var query = firestore
//         .collection('user_profile')
//         .where('role', isEqualTo: role)
//         .limit(documentLimit);

//     if (lastDocument != null) {
//       query = query.startAfterDocument(lastDocument);
//     }

//     final querySnapshot = await query.get();

//     if (querySnapshot.docs.isEmpty) {
//       break;
//     } else {
//       /// loop over docs and and call loadAppUserProfile
//       for (final doc in querySnapshot.docs) {
//         var user = PublicUser.empty;
//         if (publicUsersList.isEmpty) {
//           final userDoc = await fetchPublicUser(firestore, doc.id);
//           user = PublicUser.fromJson(userDoc);
//         } else {
//           user = publicUsersList.firstWhere(
//             (user) => user.id == doc.id,
//             orElse: () => PublicUser.empty,
//           );
//         }
//         final appUser = await loadAppUserProfile(
//           firestore,
//           appUserDoc: doc,
//           user: AppUser(id: user.id, userProfile: user),
//           keepExistingProfile: true,
//         );
//         allDocumentsList.add(appUser);
//       }
//       lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1];
//     }

//     if (querySnapshot.docs.length < documentLimit) {
//       break;
//     }
//   }
//   return allDocumentsList;
// }
