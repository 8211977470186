import type { Firestore } from 'firebase/firestore'
import { Cubit } from './core'
import { collection, doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import type { Room } from 'livekit-client'
import type { ObservableMap, ObservableSet } from 'mobx'
import { action, computed, makeObservable, observable } from 'mobx'
import type { ObservableCollection } from '../firestore-mobx'
import type { StaticModelCollection } from '../firestore-mobx/model'
import type { FirestoreRoomStateWrite } from '../firestore/RoomState'
import {
  roomStateAddGroupLeader,
  roomStateSetGroupLeaders,
} from '../firestore/RoomState'
import { createRoomStateFeedback } from '../firestore/RoomStateFeedback'
import type { FirestoreSlideDeckExhibit } from '../firestore/SlideDeckExhibit/schema'
import type { BreakoutUser } from '../models/BreakoutUser'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { PublicUser } from '../models/PublicUser'
import { RoomState } from '../models/RoomState'
import { RoomStateAnswer } from '../models/RoomStateAnswer'
import { RoomStateFeedback } from '../models/RoomStateFeedback'
import { RoomStateSummary } from '../models/RoomStateSummary'
import { SettingsProcessingVideo } from '../models/SettingsProcessingVideo'
import { SlideCaption } from '../models/SlideCaption'
import { SlideDeck } from '../models/SlideDeck'
import { SlideDeckExhibit } from '../models/SlideDeckExhibit'
import { SlideModel } from '../models/SlideModel'
import { SlideQuestion } from '../models/SlideQuestion'
import { UserProfileRoomToken } from '../models/UserProfileRoomToken'
import { EventEmitter } from '../util/EventEmitter'
import { similarity } from '../util/arrays'
import { MeetingChatController } from './meeting/MeetingChatController'
import { MeetingDataFetcher } from './meeting/MeetingDataFetcher'
import { MeetingLivekitController } from './meeting/MeetingLivekitController'
import { MeetingQuestion } from './meeting/MeetingQuestion'
import { SequestrationController } from './meeting/SequestrationController'
import { SlideTimerController } from './meeting/SlideTimerController'
import { GroupQuizSlide } from './meeting/slides/GroupQuizSlide'
import { InteractivePollSlide } from './meeting/slides/InteractivePollSlide'
import { InteractiveQuizSlide } from './meeting/slides/InteractiveQuizSlide'
import { MeetingResultsSlide } from './meeting/slides/MeetingResultsSlide'
import { Slide } from './meeting/slides/Slide'
import { SoloQuizSlide } from './meeting/slides/SoloQuizSlide'
import { BroadcastState, SessionMuting, type MeetingSidebarTab } from '../types'
import { SlideQuestionType } from '../models/SlideQuestionType'
import { SlideType } from '../firestore/Slide/types'

// TODO: move to cubits as MeetingCubit
/**
 * The Meeting class is the main class that represents a meeting in the application.
 *
 * The Meeting class is responsible for managing the state of the meeting.
 *
 * It houses all observable data and actions that can be performed on the meeting.
 *
 * It also is the access point to any computed properties that are derived from the observable data.
 */
export class MeetingCubit extends Cubit {
  firestore: Firestore
  repository: FirebaseRepository
  currentUser: BreakoutUser

  roomId: string
  livekitRoom: Room | null = null
  livekitRoomReady: boolean = false

  // needed here because certain actions switch the state of the tab view
  sidebarTab: MeetingSidebarTab = 'peers'

  broadcastState: BroadcastState = BroadcastState.uninitialized
  broadcastPosition: number | null = null
  broadcastDuration: number | null = null
  activeSpeakerId: string | null = null
  progressBarValue: number = 0
  isPreview: boolean = false
  useMockResults: boolean = false
  displayCaptions: boolean = false

  // used to determine if we should enable scroll on the meeting view
  screenShareActive = false

  unsubscribers: (() => void)[] = []

  // userAnswers: IObservableArray<UserAnswer> = observable.array([])
  // groupAnswers: IObservableArray<UserAnswer> = observable.array([])
  // questionAnswers: ObservableMap<string, UserAnswer[]> = observable.map({})
  // userIds: IObservableArray<string> = observable.array([])
  // groupLeaderUserIds: IObservableArray<string> = observable.array([])
  // users: ObservableMap<string, string> = observable.map({})
  // userList: IObservableArray<PublicUser> = observable.array([])

  exhibits: ObservableCollection<FirestoreSlideDeckExhibit> | null = null

  streamStatus: ObservableMap<
    string,
    ObservableMap<
      string,
      {
        status: string
        position: number
        duration: number
      }
    >
  > = observable.map({})

  localCommands: EventEmitter

  // These are supporting classes that are used to break up the Meeting class
  // They should never be used directly outside the Meeting class, so they are private
  protected dataFetcher: MeetingDataFetcher
  protected livekitController: MeetingLivekitController
  protected slideTimerController: SlideTimerController

  roomState: RoomState
  slideDeck: SlideDeck
  roomToken: UserProfileRoomToken
  slideDeckExhibits: StaticModelCollection<SlideDeckExhibit>
  slideDeckSlides: StaticModelCollection<SlideModel>
  slideDeckQuestions: StaticModelCollection<SlideQuestion>
  roomStateAnswersForGroup: StaticModelCollection<RoomStateAnswer>
  roomStateFeedback: RoomStateFeedback
  roomStateSummary: RoomStateSummary
  settingsProcessingVideo: SettingsProcessingVideo
  slideCaptions: StaticModelCollection<SlideCaption>

  roomFailedToLoad: boolean = false

  roomStateAnswersPerUser = observable.map<
    string,
    StaticModelCollection<RoomStateAnswer>
  >()

  participantIds = observable.array<string>([])

  seenExhibitIds: ObservableSet<string> = observable.set<string>([])

  mediaPermissionsFailed:
    | 'camera'
    | 'mic'
    | 'devices'
    | 'screenshare'
    | 'inuse'
    | undefined = undefined

  constructor(
    currentUser: BreakoutUser,
    repository: FirebaseRepository,
    roomId: string
  ) {
    super()
    this.roomId = roomId
    this.currentUser = currentUser

    this.repository = repository
    this.firestore = repository.firestore

    this.roomState = RoomState.empty(repository)
    this.slideDeck = SlideDeck.empty(repository)
    this.roomToken = UserProfileRoomToken.empty(repository)
    this.slideDeckExhibits = SlideDeckExhibit.emptyCollection(repository)
    this.slideDeckSlides = SlideModel.emptyCollection(repository)
    this.slideDeckQuestions = SlideQuestion.emptyCollection(repository)
    this.roomStateAnswersForGroup = RoomStateAnswer.emptyCollection(repository)
    this.roomStateFeedback = RoomStateFeedback.empty(repository)
    this.roomStateSummary = RoomStateSummary.empty(repository)
    this.settingsProcessingVideo = SettingsProcessingVideo.empty(repository)
    this.slideCaptions = SlideCaption.emptyCollection(repository)

    this.localCommands = new EventEmitter()
    this.dataFetcher = this.constructDataFetcher()
    this.livekitController = this.constructLivekitController()
    this.slideTimerController = this.constructSlideTimerController()

    makeObservable(this, {
      // observables
      livekitRoom: observable,
      livekitRoomReady: observable,
      broadcastState: observable,
      sidebarTab: observable,
      broadcastPosition: observable,
      broadcastDuration: observable,
      activeSpeakerId: observable,
      progressBarValue: observable,
      displayCaptions: observable,
      screenShareActive: observable,
      mediaPermissionsFailed: observable,
      roomFailedToLoad: observable,
      // actions
      setSidebarTab: action,
      setSlide: action,
      // replaceUserAnswers: action,
      // replaceGroupAnswers: action,
      // replaceQuestions: action,
      setLivekitRoomReady: action,
      setLivekitRoom: action,
      setBroadcastState: action,
      setBroadcastPosition: action,
      setBroadcastDuration: action,
      clearBroadcastDuration: action,
      setBroadcastPositionAndDuration: action,
      updateSlideStreamStatus: action,
      updateRoomState: action,
      updateActiveSpeaker: action,
      updateProgressBar: action,
      shareExhibit: action,
      stopSharingExhibit: action,
      toggleCaptions: action,
      updateParticipantIds: action,
      setMediaPermissionsFailed: action,
      markRoomFailedToLoad: action,
      markExhibitAsSeen: action,
      // computeds
      breakoutParticipantIsPresent: computed,
      userAnswers: computed,
      groupAnswers: computed,
      livekitToken: computed,
      questionAnswers: computed,
      livekitUrl: computed,
      activeSlide: computed,
      activeSlideChangedAt: computed,
      currentSlide: computed,
      currentUserIsGroupLeader: computed,
      currentSlideExhibits: computed,
      currentSlideQuestions: computed,
      discussion_count: computed,
      discussion_index: computed,
      timeLeftInSession: computed,
      currentSlideIsBroadcast: computed,
      currentSlideDuration: computed,
      minutesLeftOnSlide: computed,
      // userMap: computed,
      isSharingExhibit: computed,
      isSequestered: computed,
      // groupLeaderUser: computed,
      broadcastIsReady: computed,
      hasFeedback: computed,
      slides: computed,
      users: computed,
      presentUserIds: computed,
      groupLeaderUserIds: computed,
      allExhibitsSorted: computed,
      hiddenUserIds: computed,
      isDemo: computed,
      slideProgressIsComplete: computed,
      unseenExhibitCount: computed,
    })
  }

  async initialize() {
    this.dataFetcher.start()
    if (!this.isPreview) {
      this.chat.initialize()
      this.livekitController.initialize()
      this.sequestrationController.initialize()
    }

    if (!this.isPreview) {
      this.addReaction({
        whenThisChanges: () => this.activeExhibit,
        thenRunThisCode: (exhibit) => {
          if (exhibit) {
            this.setSidebarTab('peers')
          } else {
            this.setSidebarTab('exhibits')
          }
        },
      })
    }
  }

  constructDataFetcher(): MeetingDataFetcher {
    return new MeetingDataFetcher(this)
  }

  constructSlideTimerController(): SlideTimerController {
    return new SlideTimerController(this)
  }

  constructLivekitController(): MeetingLivekitController {
    return new MeetingLivekitController(this)
  }

  private _sequestrationController?: SequestrationController
  get sequestrationController() {
    return (this._sequestrationController ??= new SequestrationController(
      this,
      this.livekitController
    ))
  }

  get transcriptController() {
    return this.livekitController.transcriptController
  }

  /// Relations

  chatController?: MeetingChatController
  get chat() {
    this.chatController ??= new MeetingChatController(this)
    return this.chatController
  }

  // This function houses all the logic that should be run when the active slide changes
  onSlideChange() {
    if (this.currentSlide?.type === SlideType.discussion) {
      if (this.currentSlide?.hasImage) {
        this.setSidebarTab('peers')
      } else if (this.roomState.data.activeSlide === 0) {
        // if the first slide is a discussion slide, set the sidebar tab to agenda
        this.setSidebarTab('agenda')
      } else {
        this.setSidebarTab('exhibits')
      }
    } else if (this.currentSlide?.type === SlideType.professorFeedback) {
      if (this.currentSlide?.hasImage) {
        this.setSidebarTab('peers')
      }
    } else if (this.currentSlide?.type === SlideType.video) {
      this.setSidebarTab('peers')
    }

    this.setBroadcastState(BroadcastState.uninitialized)
    this.setBroadcastPosition(0)
    this.livekitController.onSlideChange()
  }

  dispose() {
    this.dataFetcher.dispose()
    this.unsubscribers.forEach((sub) => sub())
    this.livekitController.dispose()
    this.sequestrationController.dispose()
    this.slideTimerController.dispose()
    if (!this.isPreview) {
      this.chat.dispose()
    }
    return super.dispose()
  }

  addUnsubscriber(subscriber: () => void) {
    this.unsubscribers.push(subscriber)
  }

  markRoomFailedToLoad() {
    this.roomFailedToLoad = true
  }

  setBroadcastState(state: BroadcastState) {
    // uninitialized cannot transition to stopped
    if (
      this.broadcastState === BroadcastState.uninitialized &&
      state === BroadcastState.stopped
    ) {
      return
    }
    if (state === BroadcastState.stopped) {
      if (this.currentSlide?.type === SlideType.video) {
        this.progressBarValue = 1
      }
    }
    this.broadcastState = state
  }

  setBroadcastPosition(position: number) {
    this.broadcastPosition = position
  }

  setBroadcastDuration(duration: number) {
    this.broadcastDuration = duration
  }

  clearBroadcastDuration() {
    this.broadcastDuration = null
  }

  setBroadcastPositionAndDuration(position: number, duration: number) {
    this.broadcastPosition = position
    this.broadcastDuration = duration
    if (this.currentSlide?.type === SlideType.video) {
      this.progressBarValue = position / duration
    }
  }

  updateSlideStreamStatus(
    slideId: string,
    userId: string,
    status: string,
    position: number,
    duration: number
  ) {
    const slideStatus = this.streamStatus.get(slideId)

    if (!slideStatus) {
      this.streamStatus.set(slideId, observable.map())
    }

    const slideStatusMap = this.streamStatus.get(slideId)

    slideStatusMap?.set(userId, {
      position: position,
      duration: duration,
      status: status,
    })
  }

  sendStreamStatus(status: string, position: number, duration: number) {
    if (duration > 0) {
      const progress = position / duration
      this.updateProgressBar(progress)
    }
    this.livekitController.sendStreamStatus(status, position, duration)
  }

  // replaceUserAnswers(newUserAnswers: UserAnswer[]) {
  //   this.userAnswers.replace(newUserAnswers)
  // }

  // replaceGroupAnswers(newGroupAnswers: UserAnswer[]) {
  //   this.groupAnswers.replace(newGroupAnswers)
  // }

  // replaceQuestions(newQuestions: MeetingQuestion[]) {
  //   this.questions.replace(newQuestions)
  // }

  updateRoomState = (newRoomState: RoomState) => {
    if (newRoomState.data === undefined) return

    const hasSlideChanged =
      this.roomState.data.activeSlide !== newRoomState.data.activeSlide

    const initializing = this.roomState.isEmpty && newRoomState.isNotEmpty
    if (initializing) {
      // delay two seconds and call firstTimeInRoomFunction
      setTimeout(() => {
        this.firstTimeInRoomFunction()
      }, 2500)
      // wait for slides to load can call onSlideChange
      // this will allow us to select the correct agenda or
      // peers slide on first load
      setTimeout(() => {
        this.onSlideChange()
      }, 500)
    }

    this.roomState.replaceModel(newRoomState)

    if (initializing) {
      this.roomState.startRoomIfNotStarted()
    }

    if (hasSlideChanged) {
      this.logEvent('loaded_slide', { slide_index: this.activeSlide })
      this.resetSlideTimer()
      this.onSlideChange()
    }
  }

  firstTimeInRoomFunction = () => {
    this.resetSlideTimer()
    if (
      this.slides.length &&
      this.currentSlide?.type === SlideType.video &&
      this.activeSlide !== null
    ) {
      this.dataFetcher.resetCaptions()

      // if there is no breakout participant, set the progress bar to 1
      if (!this.livekitController.broadcastMonitor?.getBreakoutParticipant()) {
        this.progressBarValue = 1
        this.broadcastState = BroadcastState.stopped
      } else {
        if (this.currentUserIsGroupLeader) {
          this.sendBroadcastMessage('play')
        }
      }
    }
  }

  setSlide(index: number) {
    const newSlideIsDifferent = this.activeSlide !== index

    if (!newSlideIsDifferent) return

    if (this.currentSlideIsBroadcast) {
      this.sendBroadcastMessage('stop')
      this.setBroadcastPosition(0)
    }

    this.resetSlideTimer()

    const payload: Partial<FirestoreRoomStateWrite> = {
      activeSlide: index,
      activeExhibitId: null,
      activeSlideChangedAt: serverTimestamp(),
    }

    // if admin navigates to the first slide, start the room
    if (index === 0) {
      payload.roomStartedAt = serverTimestamp()
    }

    updateDoc(this.roomStateRef(), payload)
  }

  nextSlide() {
    this.logEvent('next_slide', { slide_index: this.activeSlide + 1 })
    if (this.activeSlide < this.slides.length - 1) {
      this.setSlide(this.activeSlide + 1)
    }
  }

  roomStateRef() {
    return doc(collection(this.firestore, 'room_state'), this.roomId)
  }

  setLivekitRoomReady(ready: boolean) {
    this.livekitRoomReady = ready
  }

  setLivekitRoom(room: Room) {
    this.livekitRoom = room
    this.livekitController.setupLivekitListeners()
  }

  sendBroadcastMessage(message: string) {
    // Only group leaders can send broadcast messages
    if (!this.currentUserIsGroupLeader) return

    if (message === 'stop') {
      this.logEvent('room_video_stop')
    }
    this.logEvent('room_video_action', { action: message })

    this.livekitController.sendBroadcastMessage(message)
  }

  muteParticipant(userId: string) {
    // authorization set vis feature flag
    if (!this.currentUserAllowedToMute) return

    this.livekitController.sendParticipantMessage(userId, 'muteMic')
  }

  toggleBroadcastPlaying = () => {
    if (!this.currentUserIsGroupLeader) return
    if (this.broadcastState === BroadcastState.stopped) return

    const isPlaying = this.broadcastState === BroadcastState.playing
    this.sendBroadcastMessage(isPlaying ? 'pause' : 'play')
  }

  setSidebarTab(tab: MeetingSidebarTab) {
    this.sidebarTab = tab
  }

  cancelSlideTimer() {
    this.slideTimerController.cancelSlideTimer()
  }

  resetSlideTimer() {
    this.slideTimerController.resetSlideTimer()
  }

  endTimer() {
    this.slideTimerController.endTimer()
  }

  updateProgressBar(progress: number) {
    if (progress > 1) progress = 1

    this.progressBarValue = progress
  }

  // Delegates

  mute() {
    this.livekitController.mute()
  }

  async toggleAudio() {
    if (this.isSequestered) return
    try {
      await this.livekitController.toggleAudio()
    } catch (e) {
      const error = e as Error
      if (error.name === 'NotAllowedError') {
        this.setMediaPermissionsFailed('mic')
      }
    }
  }

  unmute() {
    if (this.isSequestered) return

    this.livekitController.unmute()
  }

  isAudioEnabled() {
    return this.livekitController.isAudioEnabled()
  }

  async toggleVideo() {
    if (this.isSequestered) return

    try {
      await this.livekitController.toggleVideo()
    } catch (e) {
      const error = e as Error
      if (error.name === 'NotAllowedError') {
        this.setMediaPermissionsFailed('camera')
      }
    }
  }

  async enableVideo() {
    if (this.isSequestered) return

    try {
      await this.livekitController.enableVideo()
    } catch (e) {
      const error = e as Error
      if (error.name === 'NotAllowedError') {
        this.setMediaPermissionsFailed('camera')
      }
    }
  }

  setMediaPermissionsFailed(
    mode: 'camera' | 'mic' | 'devices' | 'screenshare' | 'inuse' | undefined
  ) {
    this.mediaPermissionsFailed = mode
  }

  disableVideo() {
    this.livekitController.disableVideo()
  }

  isVideoEnabled() {
    return this.livekitController.isVideoEnabled()
  }

  async toggleScreenShare() {
    if (this.isSequestered) return

    try {
      await this.livekitController.toggleScreenShare()
    } catch (e) {
      const error = e as Error
      if (error.name === 'NotAllowedError') {
        this.setMediaPermissionsFailed('screenshare')
      }
    }
  }

  toggleVideoForParticipant(userId: string) {
    this.livekitController.toggleVideoForParticipant(userId)
  }

  seekBroadcast(position: number) {
    this.livekitController.seekBroadcast(position)
  }

  updateActiveSpeaker(userId: string | null) {
    this.activeSpeakerId = userId
  }

  shareExhibit(exhibitId: string): void {
    updateDoc(this.roomStateRef(), {
      activeExhibitId: exhibitId,
    })
  }

  stopSharingExhibit(): void {
    updateDoc(this.roomStateRef(), {
      activeExhibitId: null,
    })
  }

  promoteSelfToLeader() {
    roomStateSetGroupLeaders(this.firestore, this.roomId, [
      this.currentUser.uid,
    ])
    this.logEvent('assert_leadership')
  }

  promoteToGroupLeader(id: string) {
    return roomStateAddGroupLeader(this.firestore, this.roomId, id)
  }

  toggleCaptions() {
    this.displayCaptions = !this.displayCaptions
  }

  resolveSlide(model: SlideModel, index: number) {
    const type: SlideType = model.data.slideType
    switch (type) {
      case SlideType.sessionResults:
        return new MeetingResultsSlide(this, model, index)

      case SlideType.interactivePoll:
        return new InteractivePollSlide(this, model, index)

      case SlideType.interactiveQuiz:
        return new InteractiveQuizSlide(this, model, index)

      case SlideType.groupQuiz:
        return new GroupQuizSlide(this, model, index)

      case SlideType.soloQuiz:
        return new SoloQuizSlide(this, model, index)

      default:
        return new Slide(this, model, index)
    }
  }

  markExhibitAsSeen(exhibitId: string) {
    this.seenExhibitIds.add(exhibitId)
  }

  markExhibitAsSeenWithDelay(exhibitId: string) {
    setTimeout(() => {
      this.markExhibitAsSeen(exhibitId)
    }, 1000)
  }

  get unseenExhibitCount() {
    const exhibitIds = this.currentExhibitsUpToThisSlide.map(
      (exhibit) => exhibit.id
    )
    return exhibitIds.filter((id) => !this.seenExhibitIds.has(id)).length
  }

  get isSharingExhibit(): boolean {
    return this.activeExhibitId !== null
  }

  async sendFeedback(score: number, comment: string): Promise<void> {
    return createRoomStateFeedback(this.firestore, {
      comment: comment,
      roomId: this.roomId,
      score: score,
      userId: this.currentUser.uid,
    })
  }

  updateParticipantIds(ids: string[]) {
    this.participantIds.replace(ids)
  }

  async reconnectRoom() {
    if (!this.livekitRoom) return

    await this.livekitRoom.disconnect()
    await new Promise((resolve) => setTimeout(resolve, 1000))
    await this.livekitRoom.connect(this.livekitUrl, this.livekitToken, {
      autoSubscribe: true,
    })
  }

  // Computed properties

  get slideProgressIsComplete() {
    return this.progressBarValue >= 1
  }

  get isDemo() {
    return !!this.roomState.data.isDemo
  }

  // @computed
  get breakoutParticipantIsPresent() {
    return !!this.livekitController.broadcastMonitor?.getBreakoutParticipant()
  }

  // an array of answers for a particular user
  get dataForQuizIsReady() {
    return (
      this.slideDeckQuestions.isLoaded &&
      this.roomStateAnswersPerUser.get(this.currentUser.uid)?.isLoaded
    )
  }

  get streamingMode() {
    return this.roomState.data.videoMethod === 'streaming'
  }

  get users() {
    return this.repository.userStore.getUsers(this.roomState.data.userIds)
  }

  get usersLoading() {
    return this.users.some((user) => user.isLoading)
  }

  /**
   * @deprecated use session results from MeetingResultsCubit for future views
   */
  get sessionResults(): {
    pollAgreement: number
    quizPerformance: number
  } {
    /// loop over state.slideQuestions and get questions
    /// if it is a poll type question
    /// loop over state.roomStateAnswersByQuestion and get answers
    /// extract an array of bools from the from questions that are of
    /// type poll. 1 = true, 0 = false
    /// pass the list to the similarity function
    /// add the results from each question to a double and then divide
    /// by the number of questions and set that to pollAgreement
    /// calculate the quizPerformance as well
    /// (simple average of correct answers)
    let pollTotal = 0.0
    let pollCount = 0
    let correctAnswers = 0
    let quizCount = 0

    for (const question of this.questions) {
      if (
        question.questionType === SlideQuestionType.poll ||
        question.questionType === SlideQuestionType.customPoll
      ) {
        const answers = this.questionAnswers.get(question.id) ?? []
        const answerList = answers.map((answer) => answer.data.answer === 1)
        pollTotal += similarity(answerList)
        pollCount++
      } else {
        const answers = this.questionAnswers.get(question.id) ?? []
        for (const answer of answers) {
          if (answer.data.answer === question.data.correct) {
            correctAnswers++
          }
          quizCount++
        }
      }
    }

    const pollAgreement =
      Math.round((pollTotal / Math.max(pollCount, 1)) * 100) / 100
    const quizPerformance =
      Math.round((correctAnswers / Math.max(quizCount, 1)) * 100) / 100

    return {
      pollAgreement,
      quizPerformance,
    }
  }

  // getter for present users where a user is present if they are in users and
  // in the livekit room
  get presentUserIds() {
    const presentIdentities = this.participantIds
    return this.users
      .filter((user) => {
        return presentIdentities.includes(user.id)
      })
      .map((user) => user.id)
  }

  get userAnswers() {
    const array = this.roomStateAnswersPerUser.get(this.currentUser.uid)

    if (!array) return []

    return array.models
  }

  // @computed
  get groupAnswers() {
    return this.roomStateAnswersForGroup.models
  }

  // @computed
  get activeSlide(): number {
    return this.roomState.data.activeSlide || 0
  }

  // @computed
  get activeSlideChangedAt(): Date | null {
    return this.roomState.data.activeSlideChangedAt || null
  }

  // @computed
  get livekitToken() {
    return this.roomToken.data?.token
  }

  // @computed
  get livekitUrl() {
    return this.roomToken.data?.url
  }

  // @computed
  get activeExhibitId(): string | null {
    return this.roomState.data.activeExhibitId || null
  }

  // @computed
  get slideDeckId() {
    return this.roomState.data.slideDeckId
  }

  // @computed
  get sectionId() {
    return this.roomState.data.sectionId
  }

  // @computed
  get assignmentId() {
    return this.roomState.data.assignmentId
  }

  // @computed
  get slides() {
    const result = this.slideDeckSlides.models.map((model, index) => {
      return this.resolveSlide(model, index)
    })
    return result
  }

  // @computed
  get questions() {
    return this.slideDeckQuestions.models.map((model) => {
      return new MeetingQuestion(this, model)
    })
  }

  // @computed
  get questionAnswers() {
    const questionAnswers = observable.map<string, RoomStateAnswer[]>({})

    for (const answer of this.roomStateAnswersForGroup.models) {
      const questionId = answer.data.slideQuestionId
      const answers = questionAnswers.get(questionId) || []
      answers.push(answer)
      questionAnswers.set(questionId, answers)
    }

    const userIds = this.roomState.userIds
    for (const userId of userIds) {
      const userAnswers = this.roomStateAnswersPerUser.get(userId)

      if (!userAnswers) continue

      for (const answer of userAnswers.models) {
        const questionId = answer.data.slideQuestionId
        const answers = questionAnswers.get(questionId) || []
        answers.push(answer)
        questionAnswers.set(questionId, answers)
      }
    }

    return questionAnswers
  }

  get currentSlideExhibits() {
    const slide = this.currentSlide

    if (!slide) return []

    return this.allExhibitsSorted.filter((exhibit) => {
      return exhibit.data.slideId === slide.id
    })
  }

  get currentExhibitsUpToThisSlide() {
    const slide = this.currentSlide
    const slideIdsSeenSoFar = this.slides
      .filter((_slide, index) => {
        return index <= this.activeSlide
      })
      .map((slide) => slide.id)

    if (!slide) return []

    return this.allExhibitsSorted.filter((exhibit) => {
      const slideId = exhibit.data.slideId
      return slideId && slideIdsSeenSoFar.includes(slideId)
    })
  }

  get allExhibitsSorted() {
    const exhibitsWithIndices = this.slideDeckExhibits.models.map((e) => {
      const index = this.slides.findIndex((s) => s.id === e.data.slideId)
      return { exhibit: e, index }
    })

    return exhibitsWithIndices
      .sort((a, b) => {
        const aIndex = a.index
        const bIndex = b.index
        // sort by index first, then by name
        if (aIndex === bIndex) {
          return a.exhibit.data.exhibitName.localeCompare(
            b.exhibit.data.exhibitName
          )
        }
        return bIndex - aIndex
      })
      .map(({ exhibit }) => exhibit)
  }

  // TODO: this should return an Exhibit object
  // @computed
  get activeExhibit(): SlideDeckExhibit | undefined {
    const result = this.slideDeckExhibits.models.find(
      (exhibit) => exhibit.id === this.activeExhibitId
    )

    if (!result) return undefined

    return result
  }

  get currentUserIsGroupLeader() {
    return this.groupLeaderUserIds.includes(this.currentUser.uid)
  }

  get currentUserAllowedToMute() {
    // switch case on the sessionMuting value
    switch (this.repository.featureFlags.data.sessionMuting) {
      case SessionMuting.all:
        return true
      case SessionMuting.none:
        return false
      case SessionMuting.groupLeader:
        return this.currentUserIsGroupLeader
      default:
        return false
    }
  }

  get groupLeaderUserIds() {
    return this.roomState.data.groupLeaderUserIds
  }

  get hiddenUserIds() {
    return this.roomState.data.hiddenUserIds || []
  }

  get currentUserIsHidden() {
    return this.hiddenUserIds.includes(this.currentUser.uid)
  }

  get currentSlideQuestions() {
    // if slide is of type interactivePoll, interactiveQuiz, or groupSorting return the questions object
    if (
      this.currentSlide instanceof InteractivePollSlide ||
      this.currentSlide instanceof InteractiveQuizSlide ||
      this.currentSlide instanceof GroupQuizSlide ||
      this.currentSlide instanceof SoloQuizSlide
    ) {
      return this.currentSlide.questions
    }
    return []
  }

  get currentSlideTitles() {
    return this.questions.filter(
      (q) =>
        q.slideId === this.currentSlide?.id &&
        q.questionType === SlideQuestionType.title
    )
  }

  get currentSlide(): Slide | undefined {
    const slide = this.slides[this.activeSlide]
    if (!slide) return this.slides[0]

    return slide
  }

  get currentSlideId() {
    return this.currentSlide?.id
  }

  get timeLeftInSession() {
    const slidesLeft = this.slides.filter((_slide, index) => {
      return index > this.activeSlide
    })
    return slidesLeft.reduce((acc, slide) => {
      return acc + slide.durationInMinutes
    }, 0)
  }

  get currentSlideIsBroadcast() {
    return this.currentSlide?.type === SlideType.video
  }

  get currentSlideDuration() {
    if (this.currentSlide?.type === SlideType.video) {
      return this.currentSlide?.slideVideoDuration || 0
    }
    return this.currentSlide?.duration || 0
  }

  get minutesLeftOnSlide() {
    const minutesLeft = Math.round(
      (this.currentSlideDuration / 60) * (1 - this.progressBarValue)
    )

    return minutesLeft
  }

  get progressIsDone() {
    return this.progressBarValue === 1
  }

  get progressIsLoading() {
    return (
      (this.currentSlideDuration === 0 && this.currentSlideIsBroadcast) ||
      !this.progressBarValue
    )
  }

  get discussion_count() {
    return this.slides
      .slice(1)
      .filter((element) => element.type === SlideType.discussion).length
  }

  get discussion_index() {
    return this.slides
      .slice(1, this.activeSlide + 2)
      .filter((element) => element.type === SlideType.discussion).length
  }

  get hasFeedback() {
    return (
      this.roomStateFeedback.hasData && this.roomStateFeedback.data.score >= 0
    )
  }

  get isSequestered() {
    return this.sequestrationController.isSequestered
  }

  get groupLeaderUser() {
    const groupLeaderUserIds = this.roomState.data.groupLeaderUserIds
    const id = groupLeaderUserIds[0]
    if (!id) return PublicUser.empty(this.repository)

    return this.repository.userStore.getUser(id)
  }

  get broadcastIsReady() {
    return this.broadcastState !== 'uninitialized'
  }

  logEvent(name: string, params?: Record<string, unknown>) {
    this.repository.logEvent(name, {
      room_id: this.roomId,
      assignment_id: this.assignmentId,
      section_id: this.sectionId,
      slide_deck_id: this.slideDeck.id,
      ...params,
    })
  }
}
