import type { CollectionReference, DocumentReference } from 'firebase/firestore'
import {
  addDoc,
  doc,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  type DocumentData,
} from 'firebase/firestore'
import {
  collection,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreSlideDeckRubricTest } from './schema'
import { schema } from './schema'
import { SlideDeckRubricTest } from '../../models/SlideDeckRubricTest'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreSlideDeckRubricTest> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  { slideDeckId, rubricId }: { slideDeckId: string; rubricId: string }
): CollectionReference<FirestoreSlideDeckRubricTest, DocumentData> => {
  return collection(
    firestore,
    'slide_deck',
    slideDeckId,
    'rubric',
    rubricId,
    'test'
  ).withConverter(converter)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getDocRef = (
  firestore: Firestore,
  {
    slideDeckId,
    rubricId,
    rubricTestId,
  }: { slideDeckId: string; rubricId: string; rubricTestId: string }
): DocumentReference<FirestoreSlideDeckRubricTest, DocumentData> => {
  return doc(
    firestore,
    'slide_deck',
    slideDeckId,
    'rubric',
    rubricId,
    'test',
    rubricTestId
  ).withConverter(converter)
}

export const getSlideDeckRubricTests = (
  repository: FirebaseRepository,
  params: { slideDeckId: string; rubricId: string }
) => {
  const ref = getColRef(repository.firestore, params)

  const queryRef = query(ref, orderBy('updatedAt', 'desc'))

  return modelListStream(repository, queryRef, SlideDeckRubricTest)
}

export const archiveSlideRubricTest = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    rubricId,
    rubricTestId,
  }: { slideDeckId: string; rubricId: string; rubricTestId: string }
) => {
  await updateDoc(
    getDocRef(repository.firestore, { slideDeckId, rubricId, rubricTestId }),
    { archivedAt: serverTimestamp() }
  )
}
export const saveSlideDeckRubricTest = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    rubricId,
    rubricTestId,
    ...rubricTestFields
  }: {
    slideDeckId: string
    rubricTestId?: string
    rubricId: string
  } & Pick<
    FirestoreSlideDeckRubricTest,
    'rubricTestName' | 'rubricTestScore' | 'rubricTestScript'
  >
) => {
  const dataToWrite = {
    ...rubricTestFields,
    rubricId,
    updatedAt: serverTimestamp(),
  }

  const ref = getColRef(repository.firestore, {
    slideDeckId,
    rubricId,
  })

  if (rubricTestId) {
    const docRef = doc(ref, rubricTestId)
    await updateDoc(docRef, dataToWrite)
    return rubricTestId
  }

  const result = await addDoc(ref, dataToWrite)
  return result.id
}
