import z from 'zod'
import {
  firestoreTimestamp,
  nullSafeOptionalBoolean,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeOptionalNumberArray,
  nullSafeOptionalString,
  nullSafeOptionalStringArray,
} from '../shared/schema'

// this is the dart definition
// catalogId?: string;
// expiresAt?: firestore.Timestamp;
// hubspotContactId?: string;
// hubspotDealId?: number;
// oneTime?: boolean;
// role?: string;
// sectionId?: string;
// updatedAt: firestore.Timestamp | firestore.FieldValue;
// userId: string;
export const schema = z.object({
  catalogId: nullSafeOptionalString(),
  expiresAt: nullSafeOptionalFirestoreTimestamp(),
  hubspotContactId: nullSafeOptionalString(),
  hubspotDealId: nullSafeOptionalNumber(),
  isDemo: nullSafeOptionalBoolean(),
  oneTime: nullSafeOptionalBoolean(),
  role: nullSafeOptionalString(),
  sectionId: nullSafeOptionalString(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
  userId: z.string(),
  promotionId: nullSafeOptionalString(),
  // - `expirationDate` timestamp
  // - `promotionName` string
  // - `consumableCount` optional number (required if promotion is consumable=true)
  // - `collisionCode` optional string
  // - `numberField` optional number array (MUST MATCH length of `numberFieldLabels`)
  // - `stringField` optional string array (MUST MATCH length of `stringFieldLabels`)
  promotionArguments: z
    .object({
      expirationDate: firestoreTimestamp,
      promotionName: z.string(),
      consumableCount: nullSafeOptionalNumber(),
      collisionCode: nullSafeOptionalString(),
      numberField: nullSafeOptionalNumberArray(),
      stringField: nullSafeOptionalStringArray(),
    })
    .optional(),
  demoArguments: z
    .object({
      firstName: z.string(),
      lastName: z.string(),
      emailAddress: z.string().email(),
      roomId: z.string(),
    })
    .optional(),
})

export const empty = Object.freeze(
  schema.parse({
    expiresAt: { seconds: 0, nanoseconds: 0 },
    oneTime: false,
    role: '',
    userId: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export interface FirestoreInvitation extends z.infer<typeof schema> {}
