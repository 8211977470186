import type {
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import type { StreamSubscriptionActions } from 'tricklejs/dist/stream_subscription'
import { getPublicUser } from '../firestore/PublicUser'
import { getUserProfile } from '../firestore/UserProfile'
import { getUserPromotions } from '../firestore/UserPromotion/index'
import { UserProfileTokens } from '../stores/UserProfileTokens'
import type { StaticModelCollection } from '../types'
import {
  DocumentDoesNotExistError,
  InvitationConsumeResult,
  UserProfileRole,
  type FirestoreInvitation,
} from '../types'
import type { FirebaseRepository } from './FirebaseRepository'
import { PublicUser } from './PublicUser'
import { UserProfile } from './UserProfile'
import { UserPromotion } from './UserPromotion'
import { getUserPromotionRedemptions } from '../firestore/UserPromotionRedemption'
import type { UserPromotionRedemption } from './UserPromotionRedemption'
import { UnsubscribeManager } from '../util/UnsubscribeManager'
import { fetchRoomState } from '../firestore/RoomState'

type CreateInvitationResult =
  | {
      result: InvitationConsumeResult.success
      invitation?: FirestoreInvitation
    }
  | {
      result:
        | InvitationConsumeResult.errorAlreadyConsumed
        | InvitationConsumeResult.errorCreatedByYou
        | InvitationConsumeResult.errorInvitationExpired
        | InvitationConsumeResult.errorInvitationNotFound
        | InvitationConsumeResult.errorPromotionAlreadyConsumed
    }

// TODO: move to stores
/**
 * A BreakoutUser is a user of the Breakout platform, it is composed of a user profile and a public user.
 **/
export class BreakoutUser {
  uid: string
  firestore: Firestore
  repository: FirebaseRepository

  private userProfileTokens: UserProfileTokens
  private _onboardCompleteLocal = false
  private userStreamSubscription: StreamSubscriptionActions | undefined
  private profileStreamSubscription: StreamSubscriptionActions | undefined
  private userPromotionsStream: StreamSubscriptionActions | undefined

  publicUser: PublicUser
  userProfile: UserProfile
  private _userPromotions: StaticModelCollection<UserPromotion>
  private _userPromotionRedemptions = observable.map<
    string,
    UserPromotionRedemption[]
  >()

  unsubscribers = new UnsubscribeManager()

  constructor(repository: FirebaseRepository, uid: string) {
    this.uid = uid
    this.repository = repository
    const firestore = repository.firestore
    this.firestore = firestore
    this.userProfileTokens = new UserProfileTokens(repository, this)
    this.publicUser = PublicUser.empty(repository)
    this.userProfile = UserProfile.empty(repository)
    this._userPromotions = UserPromotion.emptyCollection(repository)

    makeObservable(this, {
      firstName: computed,
      lastName: computed,
      emailAddress: computed,
      role: computed,
      isLoading: computed,
      hasData: computed,
      user: computed,
      isAdmin: computed,
      isStudent: computed,
      isFaculty: computed,
      shouldShowOnboarding: computed,
      setOnboardingComplete: action,
      userPromotionRedemptionsArray: computed,
      mappedSlideDecksToConsumedTokens: computed,
      tokens: computed,
    })
  }

  initialize() {
    this.userProfileTokens.initialize()
  }

  startUserStreamIfNotRunning() {
    if (!this.uid) return
    if (this.userStreamSubscription) return

    const userStream = getPublicUser(this.repository, { userId: this.uid })
    this.userStreamSubscription = userStream.listen((user) => {
      this.publicUser.replaceModel(user)
    })
  }

  startProfileIfNotRunning() {
    if (!this.uid) return
    if (this.profileStreamSubscription) return

    const profileStream = getUserProfile(this.repository, { userId: this.uid })
    this.profileStreamSubscription = profileStream.listen((profile) => {
      this.userProfile.replaceModel(profile)
    })
  }

  startUserPromotionsStreamIfNotRunning() {
    if (!this.uid) return
    if (this.userPromotionsStream) return

    const userPromotionStream = getUserPromotions(this.repository, {
      userId: this.uid,
    })
    this.userPromotionsStream = userPromotionStream.listen((promotions) => {
      this._userPromotions.replaceModels(promotions)
    })
  }

  startedRedemptionStreams = new Set<string>()
  startUserPromotionRedemptionStreamIfNotRunning() {
    const promotions = this.userPromotions.models || []
    for (const promotion of promotions) {
      if (this.startedRedemptionStreams.has(promotion.id)) continue
      this.startedRedemptionStreams.add(promotion.id)
      const stream = getUserPromotionRedemptions(this.repository, {
        userId: this.uid,
        userPromotionId: promotion.id,
      })
      const sub = stream.listen((redemptions) => {
        runInAction(() => {
          this._userPromotionRedemptions.set(promotion.id, redemptions)
        })
      })
      this.unsubscribers.add(() => sub.cancel())
    }
  }

  dispose() {
    this.userProfileTokens.dispose()
    this.userStreamSubscription?.cancel()
    this.profileStreamSubscription?.cancel()
    this.userPromotionsStream?.cancel()
    this.unsubscribers.dispose()
  }

  get userPromotions() {
    this.startUserPromotionsStreamIfNotRunning()

    return this._userPromotions
  }

  get userPromotionRedemptions() {
    this.startUserPromotionRedemptionStreamIfNotRunning()

    return this._userPromotionRedemptions
  }

  get userPromotionRedemptionsArray() {
    return Array.from(this.userPromotionRedemptions.values()).flat()
  }

  get user() {
    this.startUserStreamIfNotRunning()
    return this.publicUser
  }

  get mappedSlideDecksToConsumedTokens() {
    return this.userProfileTokens.mappedSlideDecksToConsumedTokens
  }

  get profile() {
    this.startProfileIfNotRunning()
    return this.userProfile
  }

  get photoURL() {
    return this.user.data.imageUrl
  }

  waitForRoomStateLoaded(roomStateId: string) {
    return new Promise<void>((resolve, reject) => {
      const startTime = Date.now()
      const checkStatus = () => {
        if (Date.now() - startTime > 10000) {
          reject(new Error('waitForLoaded timed out'))
          return
        }
        fetchRoomState(this.repository, {
          roomStateId: roomStateId,
        })
          .then(() => {
            resolve()
          })
          .catch((err) => {
            if (err instanceof DocumentDoesNotExistError) {
              return reject(err)
            }
            setTimeout(checkStatus, 1000)
          })
      }
      checkStatus()
    })
  }

  async consumeInvitation(
    invitationId: string
  ): Promise<CreateInvitationResult> {
    if (!this.uid) {
      throw new Error('User must be logged in to consume invitation')
    }

    const collectionRef = collection(
      this.firestore,
      'invitation'
    ).withConverter({
      toFirestore: (data) => data,
      fromFirestore: (
        snap: QueryDocumentSnapshot<DocumentData, DocumentData>
      ) => snap.data() as FirestoreInvitation,
    })
    try {
      await this.userPromotions.waitForLoaded()
      const collisionCodes = this.userPromotions.models
        .map((p) => p.data.collisionCode?.trim())
        .filter((c): c is string => !!c)

      const invitationRef = doc(collectionRef, invitationId)

      const userInvitationRef = doc(
        collection(invitationRef, 'users'),
        this.uid
      )

      const alreadyConsumed = (await getDoc(userInvitationRef)).exists()

      if (alreadyConsumed)
        return { result: InvitationConsumeResult.errorAlreadyConsumed }

      // most of the time this will not work, we can only fetch if admin or we created in the invite
      const invitationDocInitial = await getDoc(invitationRef).catch(
        () => undefined
      )

      if (invitationDocInitial) {
        if (invitationDocInitial.exists()) {
          const invitation = invitationDocInitial.data()

          if (invitation.userId === this.uid) {
            return { result: InvitationConsumeResult.errorCreatedByYou }
          }
        } else {
          return { result: InvitationConsumeResult.errorInvitationNotFound }
        }
      }

      await setDoc(userInvitationRef, {
        updatedAt: serverTimestamp(),
      })

      let attempt = 0
      let invitation: FirestoreInvitation | undefined
      // ensure we wait for the invitation to be created - this prevents race conditions
      while (attempt < 3) {
        // still may not have sufficient permissions to fetch
        // as long as update was successful, we can return success
        const invitationDoc = await getDoc(invitationRef).catch(() => undefined)
        invitation = invitationDoc?.data()
        if (invitation) {
          break
        } else {
          attempt++
          await new Promise((resolve) => setTimeout(resolve, 1000))
        }
      }

      if (invitation && invitation.promotionId) {
        const collisionCode =
          invitation.promotionArguments?.collisionCode.trim()
        if (collisionCode) {
          if (collisionCodes.includes(collisionCode)) {
            // we have a collision, show that to the user
            return {
              result: InvitationConsumeResult.errorPromotionAlreadyConsumed,
            }
          }
        }
      }

      this.repository.logEvent('invitation_accepted', {
        invitation_id: invitationId,
        promotion_id: invitation?.promotionId,
      })

      return { result: InvitationConsumeResult.success, invitation }
    } catch (e) {
      return { result: InvitationConsumeResult.errorInvitationExpired }
    }
  }

  get hasData() {
    return this.profile.hasData && this.user.hasData
  }

  get isLoading() {
    return this.profile.isLoading || this.user.isLoading
  }

  get isLoaded() {
    return this.profile.isLoaded && this.user.isLoaded
  }

  get firstName() {
    return this.user.data.firstName
  }

  get lastName() {
    return this.user.data.lastName
  }

  get emailAddress() {
    return this.profile.data.emailAddress
  }

  get role() {
    return this.profile.hasData && this.profile.data.role
  }

  get isAdmin() {
    return this.role === UserProfileRole.admin
  }

  get isStudent() {
    return this.role === UserProfileRole.student
  }

  get isInstructor() {
    return this.role === UserProfileRole.instructor
  }

  get isTA() {
    return this.role === UserProfileRole.ta
  }

  get isFaculty() {
    return this.isInstructor || this.isTA
  }

  get shouldShowOnboarding() {
    if (!this.profile.hasData) return false
    return (
      !this.profile.data.onboardComplete &&
      !this._onboardCompleteLocal &&
      !this.isAnonymous
    )
  }

  get tokensLoading() {
    if (!this.uid) return false

    return this.userProfileTokens.isLoading
  }

  get tokensLoaded() {
    return !this.tokensLoading
  }

  get purchasesLoading() {
    if (!this.uid) return false

    return this.userProfileTokens.isLoading
  }

  get purchasesLoaded() {
    return !this.purchasesLoading
  }

  get tokens() {
    if (!this.uid) return []

    return this.userProfileTokens.tokens
  }

  get purchases() {
    if (!this.uid) return []

    return this.userProfileTokens.purchases
  }

  get availableTokens() {
    return this.userProfileTokens.tokensAvailable
  }

  get consumedTokens() {
    return this.userProfileTokens.tokensConsumed
  }

  get isAnonymous() {
    return this.userProfile.isAnonymous
  }

  get isAnonymousDefaultNames() {
    return (
      this.isAnonymous &&
      this.emailAddress === 'demo@breakoutlearning.com' &&
      this.firstName === 'Anonymous'
    )
  }

  updateName = async (firstName: string, lastName: string) => {
    if (!this.uid) return

    const payload: { [key: string]: string } = {}

    if (firstName) payload['firstName'] = firstName
    if (lastName) payload['lastName'] = lastName

    if (Object.keys(payload).length === 0) return

    const ref = doc(this.firestore, 'users', this.uid)

    await updateDoc(ref, payload)
  }

  setOnboardingComplete = () => {
    if (!this.uid) {
      throw new Error('User must be logged in to set onboarding complete')
    }

    this._onboardCompleteLocal = true

    if (this.profile.data.onboardComplete) return

    const ref = doc(this.firestore, 'user_profile', this.uid)

    // we don't need to wait for this to complete
    updateDoc(ref, {
      onboardComplete: true,
    })
  }
}
