import type {
  CollectionReference,
  DocumentData,
  PartialWithFieldValue,
} from 'firebase/firestore'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  updateDoc,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type {
  FirestoreUserPromotion,
  FirestoreUserPromotionWrite,
} from './schema'
import { schema, writeSchema } from './schema'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { UserPromotion } from '../../models/UserPromotion'
import type { StreamInterface } from 'tricklejs/dist/types'
import { modelListStream } from '../../firestore-mobx/stream'
import { PromotionType } from './types'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreUserPromotion> = {
  toFirestore: (user: PartialWithFieldValue<FirestoreUserPromotion>) => {
    return writeSchema.partial().parse(user)
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export const getColRef = (
  firestore: Firestore,
  userId: string
): CollectionReference<FirestoreUserPromotion, DocumentData> => {
  return collection(
    firestore,
    'user_profile',
    userId,
    'user_promotion'
  ).withConverter(converter)
}

export const getUserPromotions = (
  repository: FirebaseRepository,
  params: { userId: string }
): StreamInterface<UserPromotion[]> => {
  const ref = getColRef(repository.firestore, params.userId)

  return modelListStream(repository, ref, UserPromotion)
}

export const updateUserPromotion = async (
  repository: FirebaseRepository,
  userId: string,
  userPromotionId: string,
  data: Omit<FirestoreUserPromotionWrite, 'updatedAt'>
): Promise<void> => {
  const colRef = getColRef(repository.firestore, userId)
  const docRef = doc(colRef, userPromotionId)

  const payload: FirestoreUserPromotionWrite = {
    userId: userId,
    appliesTo: data.appliesTo,
    promotionId: data.promotionId,
    expirationDate: data.expirationDate,
    promotionName: data.promotionName,
    promotionSource: data.promotionSource,
    consumableCount: data.consumableCount,
    collisionCode: data.collisionCode,
    numberField: data.numberField,
    stringField: data.stringField,
    updatedAt: serverTimestamp(),
  }

  return updateDoc(docRef, payload)
}

export const createUserPromotion = async (
  repository: FirebaseRepository,
  userId: string,
  data: Omit<FirestoreUserPromotionWrite, 'updatedAt'>
): Promise<string> => {
  const colRef = getColRef(repository.firestore, userId)

  const payload: FirestoreUserPromotionWrite = {
    userId: userId,
    appliesTo: data.appliesTo,
    promotionId: data.promotionId,
    expirationDate: data.expirationDate,
    promotionName: data.promotionName,
    promotionSource: PromotionType.admin,
    consumableCount: data.consumableCount,
    collisionCode: data.collisionCode,
    numberField: data.numberField,
    stringField: data.stringField,
    updatedAt: new Date(), // this is a throw away value
  }

  const doc = await addDoc(colRef, payload)

  return doc.id
}

export const deleteUserPromotion = async (
  repository: FirebaseRepository,
  userId: string,
  userPromotionId: string
): Promise<void> => {
  const colRef = getColRef(repository.firestore, userId)
  const docRef = doc(colRef, userPromotionId)

  return deleteDoc(docRef)
}
